<template>
    <div class="setting-item">
        <div class="setting-header">
            <div class="setting-header-label">Bucket Size</div>
            <div class="setting-header-bar"><hr class="setting-header-divider" /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                    <span class="setting-name">Site</span>
                    <select class="setting-input-item" v-model="siteSettingName" @change="onLoad">
                        <option value="sitesetting01">Main</option>
                        <option value="sitesetting02">Alt 2</option>
                        <option value="sitesetting03">Alt 3</option>
                        <option value="sitesetting04">Alt 4</option>
                        <option value="sitesetting05">Alt 5</option>
                        <option value="sitesetting06">Alt 6</option>
                        <option value="sitesetting07">Alt 7</option>
                        <option value="sitesetting08">Alt 8</option>
                        <option value="sitesetting09">Alt 9</option>
                        <option value="sitesetting10">Alt 10</option>
                    </select>
                </div>
            </div>
            <div class="setting-input-container">
                <div class="setting-grid">
                    <div class="setting-input-container-grid">
                        <span class="setting-name" style="width:270px;display:flex;flex-direction:row;justify-content:flex-start">
                            <div style="width:250px">
                                Small
                            </div>
                            <div style="font-size:10pt;color:grey">
                                {{ p_hotkeys.filter((x) => x.globalId === p_settings.bucketsModel.smallHotkeyId)[0].inMinutes ? "Minutes" : "Balls" }}
                            </div>
                        </span>
                        <div class="setting-input-container setting-container-bucket" style="margin: 5px 10px 10px 0px;padding: 0%;">
                            <select class="setting-input-item-bucket" v-model="p_settings.bucketsModel.smallHotkeyId" @change="onSave" title="Select purchase option 1" aria-label="Select purchase option 1">
                                <option v-for="hotkey in p_hotkeys.filter((x) => x.hotKeyType === HotKeyTypes.PinTicketSmall || x.hotKeyType === HotKeyTypes.Other)" :key="hotkey.globalId" :value="hotkey.globalId">{{
                                    (hotkey.keySku.length > 0 ? "[" + hotkey.keySku + "] " : "") + hotkey.keyCaption + " - " + Utilities.toCurrency(hotkey.price)
                                }}</option>
                            </select>
                            <input @change="onSave" style="margin-left:10px" class="setting-input administrator-input-item-numeric" type="number" min="0" max="1000" step="1" v-model.number="p_settings.bucketsModel.smallBucket" />
                            <div style="margin-top: auto;">
                                <img src="/images/icons/plus.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="addHotkey(BucketSize.Small)" title="Add new bucket option" aria-label="Add new bucket option" />
                                <img
                                    src="/images/icons/edit.png"
                                    style="height:20px;cursor:pointer;margin-left: 10px;"
                                    @click="edit(p_settings.bucketsModel.smallHotkeyId)"
                                    v-if="p_settings.bucketsModel.smallHotkeyId != null"
                                    title="Edit bucket option"
                                    aria-label="Edit reload bucket option"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="setting-input-container-grid">
                        <span class="setting-name" style="width:270px;display:flex;flex-direction:row;justify-content:flex-start">
                            <div style="width:250px">
                                Medium
                            </div>
                            <div style="font-size:10pt;color:grey">
                                {{ p_hotkeys.filter((x) => x.globalId === p_settings.bucketsModel.mediumHotkeyId)[0].inMinutes ? "Minutes" : "Balls" }}
                            </div>
                        </span>
                        <div class="setting-input-container setting-container-bucket" style="margin: 5px 10px 10px 0px;padding: 0%;">
                            <select class="setting-input-item-bucket" v-model="p_settings.bucketsModel.mediumHotkeyId" @change="onSave" title="Select purchase option 2" aria-label="Select purchase option 2">
                                <option v-for="hotkey in p_hotkeys.filter((x) => x.hotKeyType === HotKeyTypes.PinTicketMedium || x.hotKeyType === HotKeyTypes.Other)" :key="hotkey.globalId" :value="hotkey.globalId">{{
                                    (hotkey.keySku.length > 0 ? "[" + hotkey.keySku + "] " : "") + hotkey.keyCaption + " - " + Utilities.toCurrency(hotkey.price)
                                }}</option>
                            </select>
                            <input @change="onSave" style="margin-left:10px" class="setting-input administrator-input-item-numeric" type="number" min="0" max="1000" step="1" v-model.number="p_settings.bucketsModel.mediumBucket" />
                            <div style="margin-top: auto;">
                                <img src="/images/icons/plus.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="addHotkey(BucketSize.Medium)" title="Add new bucket option" aria-label="Add new bucket option" />
                                <img
                                    src="/images/icons/edit.png"
                                    style="height:20px;cursor:pointer;margin-left: 10px;"
                                    @click="edit(p_settings.bucketsModel.mediumHotkeyId)"
                                    v-if="p_settings.bucketsModel.mediumHotkeyId != null"
                                    title="Edit bucket option"
                                    aria-label="Edit reload bucket option"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="setting-input-container-grid">
                        <span class="setting-name" style="width:270px;display:flex;flex-direction:row;justify-content:flex-start">
                            <div style="width:250px">
                                Large
                            </div>
                            <div style="font-size:10pt;color:grey">
                                {{ p_hotkeys.filter((x) => x.globalId === p_settings.bucketsModel.largeHotkeyId)[0].inMinutes ? "Minutes" : "Balls" }}
                            </div>
                        </span>
                        <div class="setting-input-container setting-container-bucket" style="margin: 5px 10px 10px 0px;padding: 0%;">
                            <select class="setting-input-item-bucket" v-model="p_settings.bucketsModel.largeHotkeyId" @change="onSave" title="Select purchase option 3" aria-label="Select purchase option 3">
                                <option v-for="hotkey in p_hotkeys.filter((x) => x.hotKeyType === HotKeyTypes.PinTicketLarge || x.hotKeyType === HotKeyTypes.Other)" :key="hotkey.globalId" :value="hotkey.globalId">{{
                                    (hotkey.keySku.length > 0 ? "[" + hotkey.keySku + "] " : "") + hotkey.keyCaption + " - " + Utilities.toCurrency(hotkey.price)
                                }}</option>
                            </select>
                            <input @change="onSave" style="margin-left:10px" class="setting-input administrator-input-item-numeric" type="number" min="0" max="1000" step="1" v-model.number="p_settings.bucketsModel.largeBucket" />
                            <div style="margin-top: auto;">
                                <img src="/images/icons/plus.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="addHotkey(BucketSize.Large)" title="Add new bucket option" aria-label="Add new bucket option" />
                                <img
                                    src="/images/icons/edit.png"
                                    style="height:20px;cursor:pointer;margin-left: 10px;"
                                    @click="edit(p_settings.bucketsModel.largeHotkeyId)"
                                    v-if="p_settings.bucketsModel.largeHotkeyId != null"
                                    title="Edit bucket option"
                                    aria-label="Edit reload bucket option"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="setting-input-container-grid">
                        <span class="setting-name" style="width:270px;display:flex;flex-direction:row;justify-content:flex-start">
                            <div style="width:250px">
                                Jumbo
                            </div>
                            <div style="font-size:10pt;color:grey">
                                {{ p_hotkeys.filter((x) => x.globalId === p_settings.bucketsModel.kingHotkeyId)[0].inMinutes ? "Minutes" : "Balls" }}
                            </div>
                        </span>
                        <div class="setting-input-container setting-container-bucket" style="margin: 5px 10px 10px 0px;padding: 0%;">
                            <select class="setting-input-item-bucket" v-model="p_settings.bucketsModel.kingHotkeyId" @change="onSave" title="Select purchase option 4" aria-label="Select purchase option 4">
                                <option v-for="hotkey in p_hotkeys.filter((x) => x.hotKeyType === HotKeyTypes.PinTicketKingSize || x.hotKeyType === HotKeyTypes.Other)" :key="hotkey.globalId" :value="hotkey.globalId">{{
                                    (hotkey.keySku.length > 0 ? "[" + hotkey.keySku + "] " : "") + hotkey.keyCaption + " - " + Utilities.toCurrency(hotkey.price)
                                }}</option>
                            </select>
                            <input @change="onSave" style="margin-left:10px" class="setting-input administrator-input-item-numeric" type="number" min="0" max="1000" step="1" v-model.number="p_settings.bucketsModel.kingBucket" />
                            <div style="margin-top: auto;">
                                <img src="/images/icons/plus.png" style="height:20px;cursor:pointer;margin-left: 10px;" @click="addHotkey(BucketSize.King)" title="Add new bucket option" aria-label="Add new bucket option" />
                                <img
                                    src="/images/icons/edit.png"
                                    style="height:20px;cursor:pointer;margin-left: 10px;"
                                    @click="edit(p_settings.bucketsModel.kingHotkeyId)"
                                    v-if="p_settings.bucketsModel.kingHotkeyId != null"
                                    title="Edit bucket option"
                                    aria-label="Edit reload bucket option"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HotkeyItemModal v-show="hotkeyDialog_show" ref="refHotkeyDialog" :designerType="DesignerTypes.PinTicket" />
        <InputBox ref="refInputBox" v-show="showInputBox" @on-cancel="showInputBox = false" />
    </div>
</template>

<script>
import { ref } from "vue";
import { HotKeyTypes, DesignerTypes, BucketSize } from "@/common/enums";
import apiSettings from "@/api/apiSettings";
import apiHotkey from "@/api/apiHotkey";
import HotkeyModel from "@/common/models/HotkeyModel";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import Utilities from "@/common/utilities";
import InputBox from "@/views/components/Shared/InputBox";
import HotkeyItemModal from "@/views/components/Administrator/HotkeyItemModal";

export default {
    props: ["siteSettings", "hotkeys"],
    components: { HotkeyItemModal, InputBox },
    setup(props) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_settings = ref(props.siteSettings);
        const siteSettingName = ref("sitesetting01");

        const refHotkeyDialog = ref(null);
        const hotkeyDialog_show = ref(false);
        const refInputBox = ref(null);
        const showInputBox = ref(false);

        var validHotkeys = [];
        validHotkeys.push(HotKeyTypes.PinTicketSmall);
        validHotkeys.push(HotKeyTypes.PinTicketMedium);
        validHotkeys.push(HotKeyTypes.PinTicketLarge);
        validHotkeys.push(HotKeyTypes.PinTicketKingSize);
        const p_hotkeys = ref(props.hotkeys.filter((x) => validHotkeys.includes(x.hotKeyType)));

        var emptyModel = new HotkeyModel();
        emptyModel.globalId = null;
        emptyModel.keyCaption = "Empty";
        p_hotkeys.value.unshift(emptyModel);

        onMounted(async () => {
            var toSave = false;
            if (p_hotkeys.value.findIndex((x) => x.globalId === p_settings.value.bucketsModel.smallHotkeyId) == -1) {
                p_settings.value.bucketsModel.smallHotkeyId = null;
                toSave = true;
            }
            if (p_hotkeys.value.findIndex((x) => x.globalId === p_settings.value.bucketsModel.mediumHotkeyId) == -1) {
                p_settings.value.bucketsModel.mediumHotkeyId = null;
                toSave = true;
            }
            if (p_hotkeys.value.findIndex((x) => x.globalId === p_settings.value.bucketsModel.largeHotkeyId) == -1) {
                p_settings.value.bucketsModel.largeHotkeyId = null;
                toSave = true;
            }
            if (p_hotkeys.value.findIndex((x) => x.globalId === p_settings.value.bucketsModel.kingHotkeyId) == -1) {
                p_settings.value.bucketsModel.kingHotkeyId = null;
                toSave = true;
            }

            if (toSave) await onSave();
        });

        const onSave = async () => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiSettings.saveSiteSettings(p_settings.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving hotkey", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onLoad = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                p_settings.value = await apiSettings.getSiteSettings(siteSettingName.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
        };

        const edit = async (hotkeyId) => {
            await refHotkeyDialog.value.showDialog(hotkeyId, async (updatedItem) => {
                if (updatedItem != null) {
                    if (updatedItem == "deleted") {
                        var indexDelete = p_hotkeys.value.findIndex((x) => x.globalId === hotkeyId);
                        if (indexDelete > -1) p_hotkeys.value.splice(indexDelete, 1);
                        if (p_settings.value.bucketsModel.smallHotkeyId == hotkeyId) p_settings.value.bucketsModel.smallHotkeyId = null;
                        if (p_settings.value.bucketsModel.mediumHotkeyId == hotkeyId) p_settings.value.bucketsModel.mediumHotkeyId = null;
                        if (p_settings.value.bucketsModel.largeHotkeyId == hotkeyId) p_settings.value.bucketsModel.largeHotkeyId = null;
                        if (p_settings.value.bucketsModel.kingHotkeyId == hotkeyId) p_settings.value.bucketsModel.kingHotkeyId = null;
                        await onSave();
                    } else {
                        p_hotkeys.value[p_hotkeys.value.findIndex((x) => x.globalId === updatedItem.globalId)] = updatedItem;
                    }
                }
                hotkeyDialog_show.value = false;
            });
            hotkeyDialog_show.value = true;
        };

        const addHotkey = async (size) => {
            refInputBox.value.initWindow("Enter hotkey name", "", 3, 20, async (itemName) => {
                try {
                    emitter.emit("server-call-start", "Creating item...");
                    var model = await apiHotkey.create(itemName);

                    var hotKeyType = HotKeyTypes.Other;

                    switch (size) {
                        case BucketSize.Small:
                            p_settings.value.bucketsModel.smallHotkeyId = model.globalId;
                            hotKeyType = HotKeyTypes.PinTicketSmall;
                            break;
                        case BucketSize.Medium:
                            p_settings.value.bucketsModel.mediumHotkeyId = model.globalId;
                            hotKeyType = HotKeyTypes.PinTicketMedium;
                            break;
                        case BucketSize.Large:
                            p_settings.value.bucketsModel.largeHotkeyId = model.globalId;
                            hotKeyType = HotKeyTypes.PinTicketLarge;
                            break;
                        case BucketSize.King:
                            p_settings.value.bucketsModel.kingHotkeyId = model.globalId;
                            hotKeyType = HotKeyTypes.PinTicketKingSize;
                            break;
                    }

                    model.hotKeyType = hotKeyType;

                    p_hotkeys.value.push(model);

                    await apiHotkey.save(model);
                    await apiSettings.saveSiteSettings(p_settings.value);
                    await edit(model.globalId);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error creating item", err]);
                }
                emitter.emit("server-call-stop");
            });
            showInputBox.value = true;
        };

        return {
            HotKeyTypes,
            p_settings,
            p_hotkeys,
            onSave,
            onLoad,
            siteSettingName,
            Utilities,
            DesignerTypes,
            edit,
            refHotkeyDialog,
            hotkeyDialog_show,
            refInputBox,
            showInputBox,
            BucketSize,
            addHotkey
        };
    }
};
</script>

<style scoped>
.setting-input-item-bucket {
    width: 200px;
}

.setting-container-bucket {
    width: 400px;
}

@media (orientation: landscape) {
    .setting-grid {
        display: grid;
        grid-template-columns: auto auto;
        width: 50%;
    }
}

@media (orientation: portrait) {
    .setting-grid {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
    }
}

</style>
