export default class ReportSummaryModel {
    manualEntry: number | undefined;
    pinCode: number | undefined;
    rangeCardReloads: number | undefined;
    salesItems: number | undefined;
    doorTurnstile: number | undefined;
    bucketPurchaseCash: number | undefined;
    bucketPurchaseCreditCards: number | undefined;
    bucketPurchaseRangeCardReloads: number | undefined;
    incomeFromPOSIntegration: number | undefined;
    creditPurchased: number | undefined;
    creditUsed: number | undefined;
    currentTotalBalance: number | undefined;
    currentTotalCustomersWithBalance: number | undefined;
    totalBallsDispensed: number | undefined;
    outstandingPinCode: number | undefined;
    outstandingDoorPinCode: number | undefined;
    incomeFromMobileReloads: number | undefined;
    incomeFromMobileTBox: number | undefined;
    incomeFromMobilePincode: number | undefined;

    incomeFromPosCash: number | undefined;
    incomeFromPosGiftCard: number | undefined;
    incomeFromPosCreditCard: number | undefined;

    giftCreditPurchased: number | undefined;
    giftCreditUsed: number | undefined;
    // giftCurrentTotalBalance: number | undefined;

    start: string | undefined;
    end: string | undefined;

    totalMinutesUsed: number | undefined;
    

    static from(json: string): ReportSummaryModel {
        let model = Object.assign(new ReportSummaryModel(), json);
                
        return model;
    }

  }
  