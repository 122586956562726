<template>
    <div class="backdrop" @click.self="onNo">
        <div class="message-box">
            <div style="display:flex;justify-content:center">{{ caption }}</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <pre>{{ message }}</pre>
                <div class="message-box-button-bar">
                    <button class="administrator-button" @click="onYes">
                        Yes
                    </button>
                    <button class="administrator-button" @click="onNo" ref="btnNo">
                        No
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
    props: ["caption", "message", "tag"],
    setup(props, { emit }) {
        const btnNo = ref(null);

        const onNo = () => {
            emit("on-no");
        };

        const onYes = () => {
            emit("on-yes", props.tag);
        };

        onMounted(() => {
            btnNo.value.focus();
        });

        return { btnNo, onNo, onYes };
    }
};
</script>

<style>
@media (orientation: landscape) {
    .message-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -105px;
        margin-left: -255px;
        width: 500px;
        height: 200px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 10px;
        border: 1px solid var(--card-body-border);
    }
}

@media (orientation: portrait) {
    .message-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 20%;
        left: 67%;
        /* margin-top: -105px;
        margin-left: -255px; */
        width: 80vw;
        height: 200px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 10px;
        border: 1px solid var(--card-body-border);
    }
}

.message-box-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.message-box-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}
</style>
