<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div style="color:white;cursor: pointer;" v-if="portalUser.isAdminPlus && nonMatched.length > 0" @click="showMissingRecords">
                        <span style="margin: 5px;">{{ nonMatched.length + " missing" }}</span>
                    </div>
                    <div v-if="portalUser.isAdminPlus" style="margin-right: 20px;">
                        <label for="file-upload" class="report-export">
                            Compare
                        </label>
                        <input id="file-upload" type="file" accept=".csv" @change="compareCsv" ref="refFileUpload" style="display: none;" />
                    </div>

                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>

            <div style="background: white; overflow-y: auto;height: 90%;font-size:10pt" v-show="dataReport != null">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row">
                        <th class="report-div-table-col-small" style="width:15%">Transaction Id</th>
                        <!-- <th class="report-div-table-col-small" style="width:11%">Date Time</th> -->
                        <th class="report-div-table-col-small" style="width:5%;">Date</th>
                        <th class="report-div-table-col-small" style="width:5%;">Time</th>
                        <th class="report-div-table-col-small" style="width:6%;">Location</th>
                        <th class="report-div-table-col-small" style="width:8%;">Item</th>
                        <th class="report-div-table-col-small" style="width:5%;text-align:right">Price</th>
                        <th class="report-div-table-col-small" style="width:5%;text-align:right">Balls Dispensed</th>
                        <th class="report-div-table-col-small" style="width:5%;text-align:right">Minutes Used</th>
                        <th class="report-div-table-col-small" style="width:4%;">Card Type</th>
                        <th class="report-div-table-col-small" style="width:6%;text-align:right">Member Number</th>
                        <th class="report-div-table-col-small" style="width:5%;text-align:right">Range Card Number</th>
                        <th class="report-div-table-col-small" style="width:8%;text-align:right">Credit Card Number</th>
                        <th class="report-div-table-col-small" style="width:7%;text-align:right">Reference Number</th>

                        <th class="report-div-table-col-small" style="width:11%;">Hint</th>
                    </tr>

                    <tr v-for="row in dataReport" :key="row.description" class="report-div-table-row">
                        <td class="report-div-table-col-small" style="" v-if="!portalUser.isAdminPlus">{{ row.transactionId }}</td>
                        <td class="report-div-table-col-small" style="" v-if="portalUser.isAdminPlus">
                            <a :style="row.isStationLogs ? 'cursor: pointer;' : 'color:black;'" @click="viewDetails(row)">{{ row.transactionId }}</a>
                        </td>
                        <!-- <td class="report-div-table-col-small" style="">{{ row.timestampDisplay }}</td> -->
                        <td class="report-div-table-col-small" :style="row.matched ? 'color:green;' : ''">{{ row.dateDisplay }}</td>
                        <td class="report-div-table-col-small" :style="row.matched ? 'color:green;' : ''">{{ row.timeDisplay }}</td>
                        <td class="report-div-table-col-small" :style="row.matched ? 'color:green;' : ''">{{ row.location }}</td>
                        <td class="report-div-table-col-small" :style="row.matched ? 'color:green;' : ''">{{ row.item }}</td>
                        <td class="report-div-table-col-small" :style="'text-align:right;' + (row.matched ? 'color:green' : '')">{{ Utilities.toCurrency(row.price) }}</td>
                        <td class="report-div-table-col-small" :style="'text-align:right;' + (row.matched ? 'color:green' : '')">{{ row.ballsDispensed }}</td>
                        <td class="report-div-table-col-small" :style="'text-align:right;' + (row.matched ? 'color:green' : '')">{{ row.minutesUsed }}</td>
                        <td class="report-div-table-col-small" :style="row.matched ? 'color:green;' : ''">{{ row.cardType }}</td>
                        <td class="report-div-table-col-small" :style="'text-align:right;' + (row.matched ? 'color:green' : '')">{{ row.customerNumber }}</td>
                        <td class="report-div-table-col-small" :style="'text-align:right;' + (row.matched ? 'color:green' : '')">{{ row.memberCardNumber }}</td>
                        <td class="report-div-table-col-small" :style="'text-align:right;' + (row.matched ? 'color:green' : '')">{{ row.creditCardNumber }}</td>
                        <td class="report-div-table-col-small" :style="'text-align:right;' + (row.matched ? 'color:green' : '')">{{ row.referenceNumber }}</td>

                        <td class="report-div-table-col-small" style="">{{ row.hint }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <StationLogViewer ref="refStationLogs" v-show="showStationLogs" @close="showStationLogs = false" />
        <DisplayBox v-show="displayBox_show" ref="refDisplayBox" />
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import StationLogViewer from "@/views/components/StationLogs/StationLogViewer.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance } from "@vue/runtime-core";
import PdfHelper from "@/common/pdfHelper";
import Helper from "@/common/helper";
import Utilities from "@/common/utilities";
import PortalUserModel from "@/common/models/PortalUserModel";
import DisplayBox from "@/views/components/Shared/DisplayBox";

export default {
    components: {
        RangeSelector,
        StationLogViewer,
        DisplayBox
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);
        const showStationLogs = ref(false);
        const refStationLogs = ref();
        const dataReport = ref();
        const refFileUpload = ref(null);
        const nonMatched = ref([]);
        const displayBox_show = ref(false);
        const refDisplayBox = ref(null);

        const reportTitle = ref("Transaction Activity");
        const reportfilename = ref("TransactionActivity");        

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        const onView = async () => {
            try {
                nonMatched.value = [];
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                dataReport.value = await apiReport.transactionActivityLogs(storage.getReportDateStart(), storage.getReportDateEnd());
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const viewDetails = async (row) => {
            if (row.isStationLogs) {
                refStationLogs.value.onViewDetails(row.stationId, row.timestampDate, row.transactionId);
                showStationLogs.value = true;
            }
        };

        var portalUser = PortalUserModel.fromToken();

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Transaction__Id: item.transactionId.toExcelSafe(),
                    Date__Time: item.timestampDisplay.toExcelSafe(),
                    Location: item.location.toExcelSafe(),
                    Item: item.item.toExcelSafe(),
                    Price: item?.price,
                    Balls__Dispensed: item.ballsDispensed,
                    Minutes__Used: item.minutesUsed,
                    Card__Type: item.cardType.toExcelSafe(),
                    Member__Number: item.customerNumber?.toExcelSafe(),
                    Member__Card__Number: item.memberCardNumber?.toExcelSafe(),
                    Credit__Card__Number: item.creditCardNumber?.toExcelSafe(),
                    Reference__Number: item.referenceNumber?.toExcelSafe(),
                    Hint: item.hint.toExcelSafe(),
                    Date: item.dateDisplay.toExcelSafe(),
                    Time: item.timeDisplay.toExcelSafe()
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        var reducer = 3;
        const savePdf = () => {
            var positions = {
                xField1: 12 - reducer * 1,
                pField1: "left",

                xField2: 60 - reducer * 2,
                pField2: "left",

                xField3: 92 - reducer * 3,
                pField3: "left",

                xField4: 109 - reducer * 4,
                pField4: "left",

                xField5: 138 - reducer * 5,
                pField5: "right",

                xField6: 150 - reducer * 6,
                pField6: "right",

                xField7: 160 - reducer * 6,
                pField7: "right",

                xField8: 176 - reducer * 7,
                pField8: "right",

                xField9: 198 - reducer * 8,
                pField9: "right",

                xField10: 234 - reducer * 9 - 10,
                pField10: "right",

                xField11: 260 - reducer * 10 - 10,
                pField11: "right",

                xField12: 287 - reducer * 11 - 10,
                pField12: "right",

                xField13: 287 - reducer * 11 - 10 + 5,
                pField13: "left"
            };

            var pdfData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Transaction__Id: item.transactionId?.toString(),
                    Date__Time: item.timestampDisplay?.toString(),
                    Location: item.location?.toString(),
                    Item: item.item.toString(),
                    Price: Utilities.toCurrency(item?.price),
                    Balls: item.ballsDispensed?.toString(),
                    Minutes: item.minutesUsed?.toString(),
                    Card__Type: item.cardType?.toString(),
                    Member__Number: item.customerNumber == undefined ? "" : item.customerNumber?.toString(),
                    Member__Card__Number: item.memberCardNumber == undefined ? "" : Helper.truncateString(item.memberCardNumber?.toString(), 20),
                    Credit__Card__Number: item.creditCardNumber == undefined ? "" : item.creditCardNumber?.toString(),
                    Reference__Number: item.referenceNumber?.toString(),
                    Hint: item.hint?.toString()
                };
                pdfData.push(row);
            });

            var pdfHeler = new PdfHelper();
            pdfHeler.savePdf(pdfData, positions, reportTitle.value, reportfilename.value, storage.getReportDateStart(), storage.getReportDateEnd(), "landscape", -4);
        };

        const compareCsv = () => {
            const input = refFileUpload.value.files[0];
            const reader = new FileReader();

            reader.onload = function(e) {
                var text = e.target.result;
                text = text.replaceAll("\r","")
                var lines = text.split("\n");
                var arrHeaders = lines[0].split(",");                
                var arrTransactions = [];
                var first = true;
                lines.forEach((line) => {
                    if (first) {
                        first = !first;
                    } else if (line.length > 0) {                               
                        var data = line.split(",");                        
                        var record = {
                            transaction: data[arrHeaders.indexOf("Transaction #")],
                            date: data[arrHeaders.indexOf("Date")],
                            method: data[arrHeaders.indexOf("Method")],
                            cardType: data[arrHeaders.indexOf("Brand")],
                            last4: data[arrHeaders.indexOf("Last 4")],
                            amount: data[arrHeaders.indexOf("Amount")].replace("$", ""),
                            authCode: data[arrHeaders.indexOf("Auth Code")],
                            status: data[arrHeaders.indexOf("Status")]
                        };
                        arrTransactions.push(record);
                    }
                });
                matchTransactions(arrTransactions.filter((x) => x.method == "SALE" && x.status == "PROCESSED"));
            };

            reader.readAsText(input);

            refFileUpload.value.value = null;
        };

        const matchTransactions = (transactions) => {
            //console.log(transactions, dataReport.value)
            //console.log(transactions)

            dataReport.value.forEach((item) => (item.matched = false));

            transactions.forEach((transaction) => {
                // filter on date
                var filtered = dataReport.value.filter((dr) => Date(dr.dateDisplay) == Date(transaction.date.split(" ")[0]));

                // Filter on last 4
                filtered = filtered.filter((dr) => dr.creditCardNumber.slice(-4) == transaction.last4);

                // Filter on amount
                filtered = filtered.filter((dr) => dr.price == transaction.amount);

                //console.log(transaction.amount,filtered)

                if (filtered.length == 1) {
                    filtered[0].matched = true;
                } else {
                    nonMatched.value.push(transaction);
                }
            });

            if (nonMatched.value.length > 0) {
                console.log(nonMatched.value);
            }
        };

        const showMissingRecords = () => {

            var message = "";

            nonMatched.value.forEach((item) => {
                message += Object.entries(item)
                    .map((x) => x[1])
                    .join(",");
                message += "\n";
            });

            refDisplayBox.value.showDialog(
                message,
                "Missing Records",
                null,
                () => {
                    return (displayBox_show.value = false);
                },
                "",
                "Close"
            );
            displayBox_show.value = true;
        };

        return {
            reportTitle,

            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,

            Utilities,
            showStationLogs,
            portalUser,
            viewDetails,
            refStationLogs,
            compareCsv,
            refFileUpload,
            nonMatched,
            displayBox_show,
            refDisplayBox,
            showMissingRecords
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 80%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}
</style>
