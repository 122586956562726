<template>
    <div class="terminal-item card-body" v-if="p_terminal.isVisible">
        <div type="button" class="card-header">
            {{ p_terminal.terminalName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave()" v-show="isDirty">
                    Save
                </button>
                <button class="administrator-button button-delete" @click="onDelete(p_terminal)" v-show="p_terminal.globalId !== -1">
                    Delete
                </button>
            </div>
            <div class="terminal-input-container">
                <span style="margin-top: 4px;">Terminal Name</span>
                <input @change="markDirty" class="terminal-input-item" type="text" v-model="p_terminal.terminalName" />
            </div>

            <div class="terminal-input-container">
                <span>Credit Card Reader</span>
                <select
                    class="terminal-input-item-select"
                    v-model="p_terminal.posCardReaderType"
                    @change="
                        markDirty();
                        p_terminal.terminalId = '';
                    "
                >
                    <option v-bind:value="PosCardReaderTypes.Augusta">ID Tech Augusta</option>
                    <option v-bind:value="PosCardReaderTypes.CardPointeTerminal">CardPointe Terminal</option>
                    <option v-bind:value="PosCardReaderTypes.Bezel8">UIC Bezel 8</option>
                </select>
            </div>

            <div class="terminal-input-container">
                <span>Terminal Id</span>
                <select class="terminal-input-item-select" v-model="p_terminal.terminalId" @change="markDirty" :disabled="p_terminal.posCardReaderType != PosCardReaderTypes.CardPointeTerminal">
                    <option v-for="terminalId in terminalIds" :key="terminalId" :value="terminalId">{{ terminalId }}</option>
                </select>
            </div>

            <div class="terminal-input-container" style="justify-content: flex-start;flex-direction: row">
                <input @change="markDirty" type="checkbox" :id="'printReceiptMerchant' + p_terminal.globalId" v-model="p_terminal.printReceiptMerchant" />
                <label :for="'printReceiptMerchant' + p_terminal.globalId">Print Merchant Receipt</label>
            </div>

            <div class="terminal-input-container" style="justify-content: flex-start;flex-direction: row">
                <input @change="markDirty" type="checkbox" :id="'printReceiptCustomer' + p_terminal.globalId" v-model="p_terminal.printReceiptCustomer" />
                <label :for="'printReceiptCustomer' + p_terminal.globalId">Print Customer Receipt</label>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import apiTerminal from "@/api/apiTerminal";
import { getCurrentInstance } from "@vue/runtime-core";
import { PosCardReaderTypes } from "@/common/enums";

export default {
    props: ["terminal", "terminalIds"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_terminal = ref(props.terminal);
        const isDirty = ref(false);

        const onSave = async () => {
            emitter.emit("server-call-start", "Saving...");
            try {
                localStorage.removeItem("terminal");
                await apiTerminal.save(p_terminal.value);
                isDirty.value = false;
                emit("on-dirty", false);
            } catch (err) {
                emitter.emit("show-alert", ["Error saving terminal", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = (item) => {
            emit("on-delete", item);
        };

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        return {
            onSave,
            onDelete,
            p_terminal,
            isDirty,
            markDirty,
            PosCardReaderTypes
        };
    }
};
</script>

<style>
@media (orientation: landscape) {
    .terminal-item {
        width: 400px;
        min-width: 100px;
    }
    .terminal-input-container {
        display: flex;
        justify-content: space-between;
        margin: 10px;
    }
}

@media (orientation: portrait) {
    .terminal-item {
        width: 95vw;
        /* min-width: 100px; */
    }
    .terminal-input-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 10px;
        flex-wrap: wrap;
    }
}

.terminal-input-item {
    width: 200px;
}

.terminal-input-item-select {
    width: 211px;
}
</style>
