<template>
    <div class="backdrop" style="z-index:100" @click.self="onNo">
        <div class="message-box">
            <div style="display:flex;justify-content:center">
                {{ messageBoxModel.caption }}
            </div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div style="text-align:center">
                    <div v-for="message in messageBoxModel.message" :key="message" id="message_box_v2_message">{{ message }}&nbsp;</div>
                </div>
                <div class="message-box-button-bar">
                    <button class="message-box-button" @click="onYes" ref="btnYes" v-show="messageBoxModel.btnYes" id="message_box_v2_yes">
                        {{ messageBoxModel.btnYesText }}
                    </button>
                    <button class="message-box-button" @click="onNo" ref="btnNo" v-show="messageBoxModel.btnNo" id="message_box_v2_no">
                        {{ messageBoxModel.btnNoText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getCurrentInstance } from "@vue/runtime-core";

export default {
    props: ["messageBoxModel"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const btnNo = ref(null);
        const btnYes = ref(null);

        const onNo = () => {
            emitter.emit("message-box-no-" + props.messageBoxModel.callbackId);
            emit("on-close");
        };

        const onYes = () => {
            emitter.emit("message-box-" + props.messageBoxModel.callbackId);
            emit("on-close");
        };

        onMounted(() => {
            if (props.messageBoxModel.defaultYes) btnYes.value.focus();
            else btnNo.value.focus();
        });

        return { btnNo, btnYes, onNo, onYes };
    }
};
</script>

<style>
@media (orientation: landscape) {
    .message-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -105px;
        margin-left: -255px;
        width: 500px;
        height: 200px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 10px;
        border: 1px solid var(--card-body-border);
    }
}


@media (orientation: portrait) {
    .message-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -105px;
        margin-left: -255px;
        width: 500px;
        height: 200px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 10px;
        border: 1px solid var(--card-body-border);
    }
}

.message-box-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.message-box-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}
</style>
