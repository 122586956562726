import axios from "axios";
import PortalLicenseModel from "@/common/models/PortalLicenseModel";
import TenantSimpleModel from "@/common/models/TenantSimpleModel";

import LogActionModel from "@/common/models/Logs/LogActionModel";
import LogDataActionModel from "@/common/models/Logs/LogDataActionModel";

import LogMessageModel from "@/common/models/Logs/LogMessageModel";
import LogDataMessageModel from "@/common/models/Logs/LogDataMessageModel";
import TenantQuickReportModel from "@/common/models/TenantQuickReportModel";

export default class apiTenant {
    
    static async get(tenantId: string): Promise<PortalLicenseModel> {                
        let response = await axios.post("api/Tenant/get", {
            tenantId: tenantId,
        });
        
        return PortalLicenseModel.from(response.data.model);
    }

    static async getLegacy(tenantId: string): Promise<PortalLicenseModel> {                
        let response = await axios.post("api/Tenant/getLegacy", {
            tenantId: tenantId,
        });

        return PortalLicenseModel.from(response.data.model);
    }

    
    static async getTenantList(): Promise<Array<TenantSimpleModel>> {
        let tenants = new Array<TenantSimpleModel>();

        let response = await axios.post("api/Tenant/GetTenantList");        
        response.data.tenants.forEach((item: any) =>
        {
            var tenant = new TenantSimpleModel();
            tenant.tenantId = item.item1
            tenant.tenantName = item.item2
            tenants.push(tenant)
        });        
        return tenants;
    }

    static async getTenantTimezoneList(): Promise<Array<TenantSimpleModel>> {
        let tenants = new Array<TenantSimpleModel>();

        let response = await axios.post("api/Tenant/GetTenantTimezoneList");        
        response.data.tenants.forEach((item: any) =>
        {
            var tenant = new TenantSimpleModel();
            tenant.tenantId = item.item1
            tenant.tenantName = item.item2
            tenant.timezone = item.item3
            tenant.isVNext = item.item4
            tenants.push(tenant)
        });        
        return tenants;
    }

    static async getLegacyTenantList(): Promise<Array<TenantSimpleModel>> {
        let tenants = new Array<TenantSimpleModel>();

        let response = await axios.post("api/Tenant/GetLegacyTenantList");        
        response.data.tenants.forEach((item: any) =>
        {
            var tenant = new TenantSimpleModel();
            tenant.tenantId = item.item1
            tenant.tenantName = item.item2
            tenants.push(tenant)
        });        
        return tenants;
    }

    static async save(siteLicense: PortalLicenseModel): Promise<void> {
        let response = await axios.post("api/Tenant/save", {
            model: siteLicense,
        });
    }

    static async create(tenantName: String, tenantAddress : String): Promise<PortalLicenseModel> {
        let response = await axios.post("api/Tenant/create", {
            tenantName: tenantName,            
            tenantAddress: tenantAddress            
        });

        return PortalLicenseModel.from(response.data.model);
    }

    static async migrate(tenantId: String): Promise<PortalLicenseModel> {
        let response = await axios.post("api/Tenant/Migrate", {
            tenantId: tenantId            
        });

        return PortalLicenseModel.from(response.data.model);
    }

    static async createLegacy(tenantName: String, tenantAddress : String): Promise<PortalLicenseModel> {
        let response = await axios.post("api/Tenant/createLegacy", {
            tenantName: tenantName,            
            tenantAddress: tenantAddress            
        });

        return PortalLicenseModel.from(response.data.model);
    }

    static async getActionLogs(start: Date, end: Date, tenantId: String, pageToken: String, pageSize: number, extraSearch: String): Promise<LogActionModel> {
        let logData = new LogActionModel();

        let response = await axios.post("api/Tenant/GetActionLogs", {
            start: start,
            end: end,
            tenantId: tenantId,            
            pageToken: pageToken,
            pageSize: pageSize,
            extraSearch: extraSearch
        });

        logData.nextPageToken = response.data.nextPageToken;

        logData.entries = new Array<LogDataActionModel>()

        response.data.entries.forEach((item: any) =>
        {
            var record = LogDataActionModel.from(item);
            logData.entries.push(record);
        });        
        return logData;
    }

    static async getMessageLogs(start: Date, end: Date, tenantId: String, pageToken: String, pageSize: number, extraSearch: String): Promise<LogMessageModel> {
        let logData = new LogMessageModel();

        let response = await axios.post("api/Tenant/GetMessageLogs", {
            start: start,
            end: end,
            tenantId: tenantId,            
            pageToken: pageToken,
            pageSize: pageSize,
            extraSearch: extraSearch
        });

        logData.nextPageToken = response.data.nextPageToken;

        logData.entries = new Array<LogDataMessageModel>()

        response.data.entries.forEach((item: any) =>
        {
            var record = LogDataMessageModel.from(item);
            logData.entries.push(record);
        });        
        return logData;
    }

    static async getApiCalls(start: Date, end: Date, tenantId: String, method: String): Promise<String> {        

        let response = await axios.post("api/Tenant/GetApiCalls", {
            start: start,
            end: end,
            tenantId: tenantId,            
            method: method,            
        });        

        return response.data.entries;
    }

    static async getMigratables(): Promise<Array<TenantSimpleModel>> {
        let tenants = new Array<TenantSimpleModel>();

        let response = await axios.post("api/Tenant/GetMigratables");        
        response.data.tenants.forEach((item: any) =>
        {
            var tenant = new TenantSimpleModel();
            tenant.tenantId = item.item1
            tenant.tenantName = item.item2
            tenants.push(tenant)
        });        
        return tenants;
    }

    static async reindexMembers(tenantId: String): Promise<void> {
        let response = await axios.post("api/Tenant/ReindexMembers", {            
            tenantId: tenantId
        });
    }

    static async getQuickReport(tenantId: string): Promise<TenantQuickReportModel> {                
        let response = await axios.post("api/Tenant/getQuickReport", {
            tenantId: tenantId,
        });
        
        return TenantQuickReportModel.from(response.data.model);
    }

    static async ballsDispensedByHourPerDay(tenantId: string, start: Date, end: Date): Promise<string> {        
        let response = await axios.post("api/Tenant/BallsDispensedByHourPerDay", {
            tenantId:tenantId,
            start: start,
            end: end
        });

        return response.data.csvData;        
    }
    
    static async exportMobilePurchases(tenantId: string, start: Date, end: Date): Promise<string> {        
        let response = await axios.post("api/Tenant/ExportMobilePurchases", {
            tenantId:tenantId,
            start: start,
            end: end
        });

        return response.data.csvData;        
    }
}
