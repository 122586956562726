<template>
    <div class="station-item card-body" style="position:relative">
        <div type="button" class="card-header">
            {{ p_station.stationName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar" v-if="editable">
                <div v-if="requiresReboot" style="font-size: 10pt;margin: auto;">
                    Requires station reboot
                </div>
                <button class="administrator-button" @click="onSave(p_station);requiresReboot=false" v-show="p_station.isDirty">
                    Save
                </button>
                <button class="administrator-button button-delete" @click="onDelete(p_station)" v-show="p_station.globalId !== ''">
                    Delete
                </button>
            </div>
            <div class="station-input-container">
                <span>Station type</span>
                <select class="station-input-item-select" v-model="p_station.stationType" @change="markDirty" :disabled="!editable">
                    <option v-bind:value="StationTypes.Dispenser">Dispenser</option>
                    <option v-bind:value="StationTypes.TBox">Tee-Box - RS</option>
                    <option v-bind:value="StationTypes.TBoxRAS">Tee-Box - RAS</option>
                    <option v-bind:value="StationTypes.Turnstile">Turnstile</option>
                </select>
            </div>
            <div class="station-input-container">
                <span>Station name</span>
                <input @change="markDirty" class="station-input-item" type="text" v-model="p_station.stationName" :disabled="!editable" />
            </div>

            <div v-if="p_station.stationType == StationTypes.Dispenser">
                <div class="station-input-container">
                    <span>Site</span>
                    <select class="station-input-item-select" v-model="p_station.siteSettings" @change="markDirty">
                        <option v-bind:value="'sitesetting01'">Main</option>
                        <option v-bind:value="'sitesetting02'">Alt 2</option>
                        <option v-bind:value="'sitesetting03'">Alt 3</option>
                        <option v-bind:value="'sitesetting04'">Alt 4</option>
                        <option v-bind:value="'sitesetting05'">Alt 5</option>
                        <option v-bind:value="'sitesetting06'">Alt 6</option>
                        <option v-bind:value="'sitesetting07'">Alt 7</option>
                        <option v-bind:value="'sitesetting08'">Alt 8</option>
                        <option v-bind:value="'sitesetting09'">Alt 9</option>
                        <option v-bind:value="'sitesetting10'">Alt 10</option>
                    </select>
                </div>                

                <div class="station-input-container">
                    <span>Card Reader</span>
                    <select class="station-input-item-select" v-model="p_station.cardReaderType" @change="markDirty();requiresReboot=true" :disabled="!editable">
                        <option v-bind:value="CardReaderTypes.Unknown">None</option>
                        <option v-bind:value="CardReaderTypes.Bezel8">Bezel 8</option>
                        <option v-bind:value="CardReaderTypes.DataCap">DataCap</option>
                        <option v-bind:value="CardReaderTypes.VP5300">VP5300</option>
                    </select>
                </div>

                <div class="hotkey-input-container">
                    <span>Currency code</span>
                    <input @change="markDirty" class="station-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_station.currencyCode" :disabled="!editable || p_station.cardReaderType != CardReaderTypes.Bezel8" />
                </div>

                <div class="hotkey-input-container">
                    <span>Start up delay</span>
                    <input @change="markDirty" class="station-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_station.startUpDelay" :disabled="!editable" />
                </div>

                <div class="station-input-container">
                    <span>Home Image</span>
                    <input @change="markDirty" class="station-input-item" type="text" v-model="p_station.stationHomeImage" :disabled="!editable" />
                </div>                

                <div class="station-input-container" v-if="portalUser.enableNorthstarPos">
                    <span>Dispenser Id</span>
                    <input @change="markDirty" class="station-input-item" type="text" v-model="p_station.dispenserId" :disabled="!editable" />
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Disable pin codes">
                    <input @change="markDirty();p_station.disablePincodes ? p_station.directPincodeScan = false : p_station.directPincodeScan=p_station.directPincodeScan;" type="checkbox" :id="'chkdisablePinCodes' + p_station.globalId" v-model="p_station.disablePincodes" :disabled="!editable" />
                    <label :for="'chkdisablePinCodes' + p_station.globalId">Disable Pin Codes</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start;margin-left: 40px;" title="Pin code QR does not need to be prefixed with '[PIN]'">
                    <input @change="markDirty" type="checkbox" :id="'chkdirectPincodeScan' + p_station.globalId" v-model="p_station.directPincodeScan" :disabled="!editable || p_station.disablePincodes" />
                    <label :for="'chkdirectPincodeScan' + p_station.globalId">Direct pin code scan</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Disable card reader">
                    <input @change="markDirty" type="checkbox" :id="'chkdisableCardReader' + p_station.globalId" v-model="p_station.disableCardReader" :disabled="!editable" />
                    <label :for="'chkdisableCardReader' + p_station.globalId">Disable Card Reader</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Disable credit cards">
                    <input @change="markDirty" type="checkbox" :id="'chkdisableCreditCards' + p_station.globalId" v-model="p_station.disableCreditCards" :disabled="!editable" />
                    <label :for="'chkdisableCreditCards' + p_station.globalId">Disable Credit Cards</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Disable member cards">
                    <input @change="markDirty" type="checkbox" :id="'chkdisableMemberCards' + p_station.globalId" v-model="p_station.disableMemberCards" :disabled="!editable" />
                    <label :for="'chkdisableMemberCards' + p_station.globalId">Disable Member Cards</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Disable mobile app">
                    <input @change="markDirty" type="checkbox" :id="'chkdisableMobileApp' + p_station.globalId" v-model="p_station.disableMobileApp" :disabled="!editable" />
                    <label :for="'chkdisableMobileApp' + p_station.globalId">Disable Mobile App</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Enable bill acceptor">
                    <input @change="markDirty" type="checkbox" :id="'chkuseBillAcceptor' + p_station.globalId" v-model="p_station.useBillAcceptor" :disabled="!editable" />
                    <label :for="'chkuseBillAcceptor' + p_station.globalId">Use Bill Acceptor</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Allow QR codes generated from member record">
                    <input @change="markDirty" type="checkbox" :id="'chkuseQrCodes' + p_station.globalId" v-model="p_station.useQrCodes" :disabled="!editable" />
                    <label :for="'chkuseQrCodes' + p_station.globalId">Use QR Member Codes</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Do not display the text 'Balls' on bucket buttons">
                    <input @change="markDirty" type="checkbox" :id="'chkhideBalls' + p_station.globalId" v-model="p_station.hideBalls" :disabled="!editable" />
                    <label :for="'chkhideBalls' + p_station.globalId">Hide Ball Text</label>
                </div>             

                <div class="station-input-container" style="justify-content: flex-start" title="Enable push dispense notification. Required for Scan and Pay">
                    <input @change="markDirty(); if (!p_station.enableDispensePush) p_station.dynamicQR = false; " type="checkbox" :id="'chkEnableDispensePush' + p_station.globalId" v-model="p_station.enableDispensePush" :disabled="!editable" />
                    <label :for="'chkEnableDispensePush' + p_station.globalId">Enable Scan and Pay</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start;margin-left: 40px;" title="Dynamic QR code for Scan and Pay">
                    <input @change="markDirty" type="checkbox" :id="'chkDynamicQR' + p_station.globalId" v-model="p_station.dynamicQR" :disabled="!editable || !p_station.enableDispensePush" />
                    <label :for="'chkDynamicQR' + p_station.globalId">Dynamic QR</label>
                </div>

                <div class="station-input-container" style="justify-content: flex-start" title="Set station as fixed drop dispenser">
                    <input @change="markDirty" type="checkbox" :id="'chkisChannelDispenser' + p_station.globalId" v-model="p_station.isChannelDispenser" :disabled="!editable" />
                    <label :for="'chkisChannelDispenser' + p_station.globalId">Channel Ball Dispenser</label>
                </div>

                <div class="hotkey-input-container" style="padding-left:30px" title="Number of balls per drop">
                    <span>Channel ball count</span>
                    <input
                        @KeyDown="markDirty"
                        @change="markDirty"
                        class="station-input-item administrator-input-item-numeric"
                        style="width:60px"
                        type="number"
                        min="0"
                        max="100"
                        step="1"
                        v-model.number="p_station.channelBallCount"
                        :disabled="!p_station.isChannelDispenser"
                    />
                </div>
            </div>

            <div class="station-input-container">
                    <span>Pulse Profile</span>
                    <select class="station-input-item-select" v-model="p_station.pulseProfile" @change="updatePulseValues()" :disabled="!editable">
                        <option v-bind:value="PulseProfileTypes.RangeServant">Range Servant</option>
                        <option v-bind:value="PulseProfileTypes.Wittek">Wittek</option>
                        <option v-bind:value="PulseProfileTypes.TimedPitch">AM Timed Pitch</option>                        
                    </select>
                </div>

            <div class="hotkey-input-container" title="Milliseconds to pulse the controller board">
                <span>Pulse length</span>
                <input @change="markDirty" class="station-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_station.pulseLength" :disabled="!editable" title="How long to pulse channel (in milliseconds)"/>
            </div>

            <div class="hotkey-input-container" title="Milliseconds between pulses">
                <span>Pulse separator</span>
                <input @change="markDirty" class="station-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_station.pulseSeparator" :disabled="!editable" title="How long to wait between pulses to channel (in milliseconds)"/>
            </div>            

            

            <div v-if="p_station.stationType == StationTypes.TBox || p_station.stationType == StationTypes.TBoxRAS">
                <div class="hotkey-input-container">
                    <span>Bay Id</span>
                    <input @change="markDirty" class="station-input-item administrator-input-item-numeric" type="number" min="0" max="999999" step="1" v-model.number="p_station.bayId" :disabled="!editable" />
                </div>
            </div>

            <div style="padding-top:40px"></div>

            <div
                class="hotkey-input-container"
                style="position:absolute;bottom:0;width:90%"
                v-if="p_station.globalId !== '' && (p_station.stationType == StationTypes.Dispenser || p_station.stationType == StationTypes.TBox || p_station.stationType == StationTypes.Turnstile)"
            >
                <div>
                    <img src="/images/icons/printer.png" class="button-link" @click="onSetupQrCode" style="width:30px" title="Print Station QR Code" />
                    <img src="/images/icons/open.png" class="button-link" @click="onOpenSetupQrCode" style="width:30px" title="Open Station QR Code Page" />
                    <img src="/images/icons/copy.png" class="button-link" @click="getStationInfo" style="width:30px" title="Copy Station Settings" />
                    <img src="/images/icons/download.png" class="button-link" @click="getStationGlobalId" style="width:30px" title="Download .select_global_identifierv2" />
                    <img src="/images/icons/purchase-copy.png" class="button-link" @click="copyStationPurchaseCode" style="width:30px" title="Copy Purchase Station QR Code" v-if="p_station.stationType == StationTypes.Dispenser && p_station.enableDispensePush && !p_station.dynamicQR"/>
                    <img src="/images/icons/purchase.png" class="button-link" @click="printStationPurchaseCode" style="width:30px" title="Print Purchase Station QR Code" v-if="p_station.stationType == StationTypes.Dispenser && p_station.enableDispensePush && !p_station.dynamicQR"/>
                </div>
                <div>
                    <img src="/images/icons/reboot.png" class="button-link" @click="onSendReboot" style="width:30px" title="Reboot Station" />
                    <img src="/images/icons/reset.png" class="button-link" @click="onSendReset" style="width:30px" title="Reset Station" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import apiStation from "@/api/apiStation";
import { computed, getCurrentInstance } from "@vue/runtime-core";
import { StationTypes, CardReaderTypes, PulseProfileTypes } from "@/common/enums";
import MessageBoxModel from "@/common/models/MessageBoxModel";
import Helper from "@/common/helper";
import PortalUserModel from "@/common/models/PortalUserModel";
import axios from "axios";
import StationCommandModel from "@/common/models/StationCommandModel";

export default {
    props: ["station"],
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const p_station = ref(props.station);
        const showLinkCopied = ref(false);

        var portalUser = PortalUserModel.fromToken();
        const editable = computed(() => portalUser.isAdminPlus);

        const onSave = async () => {
            emitter.emit("server-call-start", "Saving...");
            try {
                p_station.value.globalId = await apiStation.save(p_station.value);
                p_station.value.isDirty = false;
                emit("on-dirty", false);
            } catch (err) {
                console.log(err);
                emitter.emit("show-alert", ["Error saving station", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onSendReboot = async () => {
            emitter.emit("server-call-start", "Sending command...");
            try {
                var commandModel = new StationCommandModel();
                commandModel.stationId = p_station.value.globalId;
                commandModel.reboot = true;
                await apiStation.saveCommands(commandModel);
            } catch (err) {
                console.log(err);
                emitter.emit("show-alert", ["Error sending command", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onSendReset = async () => {
            emitter.emit("server-call-start", "Sending command...");
            try {
                var commandModel = new StationCommandModel();
                commandModel.stationId = p_station.value.globalId;
                commandModel.reset = true;
                await apiStation.saveCommands(commandModel);
            } catch (err) {
                console.log(err);
                emitter.emit("show-alert", ["Error sending command", err]);
            }
            emitter.emit("server-call-stop");
        };

        const onDelete = () => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = "Remove Station";
            messageBoxModel.message.push(`Are you sure you want to remove station '${p_station.value.stationName}'? This cannot be un-done`);
            messageBoxModel.callbackId = p_station.value.globalId + "-remove-station";
            emitter.emit("show-message-box", messageBoxModel);
        };

        emitter.off("message-box-" + p_station.value.globalId + "-remove-station");
        emitter.on("message-box-" + p_station.value.globalId + "-remove-station", async () => {
            emitter.emit("server-call-start", "Deleting...");
            try {
                await apiStation.delete(p_station.value.globalId);
                emit("on-delete", p_station.value);
            } catch (err) {
                emitter.emit("show-alert", ["Error deleting station", err]);
            }
            emitter.emit("server-call-stop");
        });

        const markDirty = () => {            
            if (!p_station.value.isDirty) {
                emit("on-dirty", true);
            }
            p_station.value.isDirty = true;
        };

        const onSetupQrCode = () => {
            var url = Helper.stationSetupCodeUrl(escape(portalUser.tenant), escape(p_station.value.stationName), escape(portalUser.tenantId), escape(p_station.value.globalId), escape(axios.defaults.baseURL), window);
            emit("on-showQrCode", url);
        };

        const printStationPurchaseCode = () => {
            var data = Helper.stationPurchaseCode(escape(p_station.value.globalId), window);
            var url = Helper.stationQrSetupCodeUrl(escape(data), window);            
            emit("on-showQrCode", url);
        };
        
        const copyStationPurchaseCode = () => {
            var data = Helper.stationPurchaseCode(escape(p_station.value.globalId), window);            

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = data;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            showLinkCopied.value = true;
            setTimeout(() => {
                showLinkCopied.value = false;
            }, 1000);
        };

        const onOpenSetupQrCode = () => {
            Helper.stationSetupCode(escape(portalUser.tenant), escape(p_station.value.stationName), escape(portalUser.tenantId), escape(p_station.value.globalId), escape(axios.defaults.baseURL), window);
        };

        const onSetupQrCodeCopied = () => {
            var data = Helper.stationSetupCodeUrl(escape(portalUser.tenant), escape(p_station.value.stationName), escape(portalUser.tenantId), escape(p_station.value.globalId), escape(axios.defaults.baseURL), window);

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = data;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            showLinkCopied.value = true;
            setTimeout(() => {
                showLinkCopied.value = false;
            }, 1000);
        };

        const getStationInfo = async () => {
            try {
                emitter.emit("server-call-start", "Getting data...");
                var info = "";
                if (p_station.value.stationType == StationTypes.Dispenser || p_station.value.stationType == StationTypes.Turnstile || p_station.value.stationType == StationTypes.TBox) {
                    info = await apiStation.getInfo(portalUser.tenantId, p_station.value.globalId);
                }

                var dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                dummy.value = info;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error getting data", err]);
            }
            emitter.emit("server-call-stop");
        };

        const getStationGlobalId = () => {
            try {
                var fileText = `{ "tenantId" : "${portalUser.tenantId}", "stationId" : "${p_station.value.globalId}", "endpoint": "${axios.defaults.baseURL.replace(/\/$/, "")}" }`;

                var element = document.createElement("a");
                element.setAttribute("href", "data:select_global_identifierv2/plain;charset=utf-8," + encodeURIComponent(fileText));
                element.setAttribute("download", ".select_global_identifierv2");

                element.style.display = "none";
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            } catch (err) {
                console.log("ERROR:", err);
            }
        };

        const updatePulseValues = () => {

            if (p_station.value.pulseProfile == PulseProfileTypes.RangeServant) {
                p_station.value.pulseLength = 50
                p_station.value.pulseSeparator = 250
            }
            else if (p_station.value.pulseProfile == PulseProfileTypes.Wittek) {
                p_station.value.pulseLength = 25
                p_station.value.pulseSeparator = 8500
            }
            else if (p_station.value.pulseProfile == PulseProfileTypes.TimedPitch) {
                p_station.value.pulseLength = 50
                p_station.value.pulseSeparator = 250
            }

            markDirty()
        }

        return {
            StationTypes,
            onSave,
            onDelete,
            p_station,
            markDirty,
            onSetupQrCode,
            editable,
            onSetupQrCodeCopied,
            onOpenSetupQrCode,
            showLinkCopied,
            onSendReboot,
            onSendReset,
            getStationInfo,
            getStationGlobalId,
            portalUser,
            copyStationPurchaseCode,
            printStationPurchaseCode,
            CardReaderTypes,
            PulseProfileTypes,
            updatePulseValues
        };
    }
};
</script>

<style>
.station-item {
    width: 300px;
    min-width: 100px;
}
.station-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.station-input-item {
    width: 150px;
}

.station-input-item-select {
    width: 161px;
}
</style>
