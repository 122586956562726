<template>
    <div class="template-base">
        <Header Title="Settings" :iconPath="'/images/icons/settings.png'" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
        </div>
        <div class="select-button-container-preferences div-body" v-if="!fetchingData">
            <SettingsRequiredFields :requiredFields="requiredFields" v-if="!portalUser.disablePreferences" />
            <SettingsCategory :settings="settings" :categoryList="categoryList" v-if="!portalUser.disablePreferences" />

            <SettingsKioskCardReload :reloadOptions="reloadOptions" :hotkeys="hotkeys" v-if="!portalUser.disableKiosk" />
            <SettingsKioskBucketSize :siteSettings="settingsSite" :hotkeys="hotkeys" v-if="!portalUser.disableKiosk" />

            <SettingsHotKeyColors :settings="hotkeyColors" v-if="!portalUser.disablePreferences" />
            <SettingsPinCodeTicket :settings="pincodeSettings" v-if="!portalUser.disablePinTicket" />

            <SettingsOperatingHours :settings="operatingHours" v-if="!portalUser.disablePreferences" />

            <SettingsPointOfSale :settings="posSettings" v-if="!portalUser.disablePreferences && portalUser.enablePos" />
            <SettingsCreditCardFee :settings="creditCardFeeSettings" v-if="!portalUser.disablePreferences" />
        </div>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import SettingsRequiredFields from "@/views/components/Administrator/SettingsRequiredFields";
import SettingsCategory from "@/views/components/Administrator/SettingsCategory";
import SettingsKioskCardReload from "@/views/components/Administrator/SettingsKioskCardReload";
import SettingsKioskBucketSize from "@/views/components/Administrator/SettingsKioskBucketSize";
import SettingsHotKeyColors from "@/views/components/Administrator/SettingsHotKeyColors";
import SettingsOperatingHours from "@/views/components/Administrator/SettingsOperatingHours";
import SettingsPinCodeTicket from "@/views/components/Administrator/SettingsPinCodeTicket";
import SettingsPointOfSale from "@/views/components/Administrator/SettingsPointOfSale";
import SettingsCreditCardFee from "@/views/components/Administrator/SettingsCreditCardFee";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import apiSettings from "@/api/apiSettings";
import { ref } from "vue";
import apiCategory from "@/api/apiCategory";
import apiHotkey from "@/api/apiHotkey";
import { getCurrentInstance, onMounted } from "@vue/runtime-core";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: {
        Header,
        SettingsRequiredFields,
        SettingsCategory,
        SettingsKioskCardReload,
        SettingsKioskBucketSize,
        SettingsHotKeyColors,
        SettingsPinCodeTicket,
        SettingsOperatingHours,
        SettingsPointOfSale,
        SettingsCreditCardFee,
        Button
    },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        onMounted(() => {
            getData();
        });
        const portalUser = ref(PortalUserModel.fromToken());

        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        const settings = ref(null);
        const requiredFields = ref(null);
        const categoryList = ref([]);

        const reloadOptions = ref(null);
        const hotkeys = ref(null);
        const settingsSite = ref(null);

        const pincodeSettings = ref(null);
        const hotkeyColors = ref(null);
        const operatingHours = ref(null);
        const posSettings = ref(null);
        const creditCardFeeSettings = ref(null);

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                categoryList.value = await apiCategory.getList();

                if (!portalUser.value.disablePreferences) {
                    requiredFields.value = await apiSettings.getRequiredFields();
                    settings.value = await apiSettings.get();
                    hotkeyColors.value = await apiSettings.getHotkeyColors();
                    operatingHours.value = await apiSettings.getOperatingHours();
                    posSettings.value = await apiSettings.getPosSettings();
                    creditCardFeeSettings.value = await apiSettings.getCreditCardFees();
                }

                if (!portalUser.value.disableKiosk) {
                    reloadOptions.value = await apiSettings.getReloadOptions();
                    settingsSite.value = await apiSettings.getSiteSettings("sitesetting01");
                    hotkeys.value = await apiHotkey.getAll();
                }

                if (!portalUser.value.disablePinTicket) {
                    pincodeSettings.value = await apiSettings.getPinCode();
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        return {
            btnExit,
            fetchingData,
            requiredFields,
            settings,
            categoryList,
            reloadOptions,
            hotkeys,
            settingsSite,
            pincodeSettings,
            hotkeyColors,
            operatingHours,
            portalUser,
            posSettings,
            creditCardFeeSettings
        };
    }
};
</script>

<style>
@media (orientation: landscape) {
    .select-button-container-preferences {
        display: flex;
        align-content: flex-start;
        background-color: var(--main-background);
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: auto;
        overflow-x: auto;
    }
}

@media (orientation: portrait) {
    .select-button-container-preferences {
        display: flex;
        align-content: flex-start;
        background-color: var(--main-background);
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.setting-item {
    width: 100%;
    min-width: 100px;
    /* background-color: lightblue; */
    margin: 5px;
    border-radius: 8px;
    padding: 5px;
    font-size: 12pt;
    font-weight: 200;
    font-family: "Roboto", sans-serif;
    align-self: flex-start;
}
.setting-input-container {
    display: flex;
    /* justify-content: space-between; */
    margin: 10px;
    padding-left: 5%;
}

.setting-input-container-grid {
    display: flex;
    flex-direction: column;
    margin: 10px 10px 10px 0px;
}

.setting-input-item {
    width: 200px;
}

.setting-name {
    width: 200px;
}

.setting-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    width: 100%;
}

.setting-header-bar {
    width: 100%;
}

.setting-header-divider {
    background-color: var(--card-body-border);
    height: 1px;
}

.setting-header-label {
    /* color: var(--card-body-border); */
    text-transform: uppercase;
    font-size: 10pt;
}

.setting-input-item-select {
    width: 161px;
}
</style>
