<template>
    <div class="template-base" style="overflow-y:auto">
        <Header Title="" class="div-header" />        
                
        <div class="section-title" v-if="superHotkeys.length > 0"><h3>Super User Settings</h3></div>
        <hr class="section-divider" v-if="superHotkeys.length > 0" />
        <div v-if="superHotkeys.length > 0" class="section-container">
            <Button class="section-button" v-for="hotkey in superHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>
                
        <div class="section-title" v-if="appHotkeys.length > 0"><h3>Applications</h3></div>
        <hr class="section-divider" v-if="appHotkeys.length > 0" />
        <div v-if="appHotkeys.length > 0" class="section-container">
            <Button class="section-button" v-for="hotkey in appHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>
                
        <div class="section-title" v-if="systemHotkeys.length > 0"><h3>System Configuration</h3></div>
        <hr class="section-divider" v-if="systemHotkeys.length > 0" />
        <div v-if="systemHotkeys.length > 0" class="section-container">
            <Button class="section-button" v-for="hotkey in systemHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>
                
        <div class="section-title" v-if="cashierHotkeys.length > 0"><h3>Cashier Configuration</h3></div>
        <hr class="section-divider" v-if="cashierHotkeys.length > 0" />
        <div v-if="cashierHotkeys.length > 0" class="section-container">
            <Button class="section-button" v-for="hotkey in cashierHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>
                
        <div class="section-title" v-if="posHotkeys.length > 0"><h3>Point of Sale Configuration</h3></div>
        <hr class="section-divider" v-if="posHotkeys.length > 0" />
        <div v-if="posHotkeys.length > 0" class="section-container">
            <Button class="section-button" v-for="hotkey in posHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>
                
        <div class="section-title" v-if="systemTools.length > 0"><h3>System Tools</h3></div>
        <hr class="section-divider" v-if="systemTools.length > 0" />
        <div v-if="systemTools.length > 0" class="section-container">
            <Button class="section-button" v-for="hotkey in systemTools" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>
                
        <div class="section-title" v-if="schedulerHotkeys.length > 0"><h3>Scheduler Configuration</h3></div>
        <hr class="section-divider" v-if="schedulerHotkeys.length > 0" />
        <div v-if="schedulerHotkeys.length > 0" class="section-container">
            <Button class="section-button" v-for="hotkey in schedulerHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>
    </div>
</template>

<script>
import ButtonModel from "@/common/models/ButtonModel";
import Button from "@/views/components/Shared/Button";
import Header from "@/views/components/Shared/Header";
import { ref } from "@vue/reactivity";
import PortalUserModel from "@/common/models/PortalUserModel";
import router from "@/router";

export default {
    components: { Button, Header },
    setup() {
        var portalUser = PortalUserModel.fromToken();

        if (isNaN(portalUser.exp)){
            router.push({ name: "Login" });
        }

        var currentDateTime = new Date().ToEpoch();
        //console.log("currentDateTime", currentDateTime);
        //console.log("portalUser.exp", portalUser.exp * 1000);
        if (currentDateTime > portalUser.exp * 1000) {
            console.log("jwt expired..");
            router.push({ name: "Login" });
        }

        const _portalUser = ref(portalUser);

        const showMe = ref(false);

        const appHotkeys = [];
        const posHotkeys = [];
        const systemTools = [];
        const schedulerHotkeys = [];
        const systemHotkeys = [];
        const cashierHotkeys = [];
        const superHotkeys = [];

        if (!portalUser.isSuperUser && !portalUser.isManager) {
            if (!portalUser.enablePos)
                appHotkeys.push(ButtonModel.CreateRouteWithIcon("Cashier", "Cashier", "/images/icons/cashier.png"));
        }

        if (portalUser.enablePos) {
            appHotkeys.push(ButtonModel.CreateRouteWithIcon("Point Of Sale", "PointOfSale", "/images/icons/pos.png"));
        }

        if ((portalUser.isAdmin || portalUser.isAdminPlus) && portalUser.enablePos && portalUser.enableInventoryManagement && !portalUser.disableInventoryManagement) {
            posHotkeys.push(ButtonModel.CreateRouteWithIcon("Inventory", "Inventory", "/images/icons/inventory.png"));
            posHotkeys.push(ButtonModel.CreateRouteWithIcon("Tax Rates", "TaxRates", "/images/icons/taxrate.png"));
            posHotkeys.push(ButtonModel.CreateRouteWithIcon("Menu Groups", "Groups", "/images/icons/group.png"));
            //posHotkeys.push(ButtonModel.CreateRouteWithIcon("Discounts", "Discounts", "/images/icons/discount.png"));
        }

        if (portalUser.enableScheduler) {
            appHotkeys.push(ButtonModel.CreateRouteWithIcon("Schedule Sheet", "Schedule Sheet", "/images/icons/schedulesheet.png"));
        }

        if (portalUser.enableScheduler && (portalUser.isAdmin || portalUser.isAdminPlus)) {
            schedulerHotkeys.push(ButtonModel.CreateRouteWithIcon("Resources", "Scheduler-Resources", "/images/icons/resource.png"));
        }

        if (portalUser.isAdmin || portalUser.isAdminPlus) {
            
            if (!portalUser.disableCustomerManagement)
                appHotkeys.push(ButtonModel.CreateRouteWithIcon("Members", "Member-Management", "/images/icons/members.png"));
            
            if (!portalUser.disablePinTicket)
                appHotkeys.push(ButtonModel.CreateRouteWithIcon("Batch Pin Codes", "Batch-PinCodes", "/images/icons/pincode.png"));
            
            appHotkeys.push(ButtonModel.CreateRouteWithIcon("Reporting", "Reporting", "/images/icons/report.png"));
            
            if (!portalUser.disableUserRights)
                systemHotkeys.push(ButtonModel.CreateRouteWithIcon("User Management", "User-Management", "/images/icons/usermanagement.png"));

            if (!portalUser.disableCustomerCategory)
                systemHotkeys.push(ButtonModel.CreateRouteWithIcon("Category Management", "Category", "/images/icons/category.png"));            

            if (!portalUser.disableHotKeys)
                systemHotkeys.push(ButtonModel.CreateRouteWithIcon("Hotkey Management", "Hotkeys", "/images/icons/hotkeys.png"));

            if (!portalUser.disablePreferences || !portalUser.disableKiosk || !portalUser.disablePinTicket)
                systemHotkeys.push(ButtonModel.CreateRouteWithIcon("Settings", "Settings", "/images/icons/settings.png"));                        

            if (portalUser.isAdminPlus) {
                systemTools.push(ButtonModel.CreateRouteWithIconColor("Station Management", "Station-Management", "/images/icons/station.png", "#EDF3E2"));
                systemTools.push(ButtonModel.CreateRouteWithIconColor("Terminal Management", "Terminal-Management", "/images/icons/terminal.png", "#EDF3E2"));                
                systemTools.push(ButtonModel.CreateRouteWithIconColor("Location Management", "Location-Management", "/images/icons/locations.png", "#EDF3E2"));                
                systemTools.push(ButtonModel.CreateRouteWithIconColor("Station Logs", "Station-Logs", "/images/icons/research.png", "#CBD1C0"));                
                systemTools.push(ButtonModel.CreateRouteWithIconColor("Exception Logs", "Exception-Logs", "/images/icons/exceptions.png", "#CBD1C0"));                
            }

            // appHotkeys.push(ButtonModel.CreateRouteWithIcon("Refunds", "Refund-Management", "/images/icons/refund.png"));
                                                
        }
    
        if (portalUser.isSuperAdmin) {
            superHotkeys.push(ButtonModel.CreateRoute("Environment Management", "Environment-Management"));

            systemHotkeys.push(ButtonModel.CreateRouteWithIcon("User Management", "User-Management", "/images/icons/usermanagement.png"));
        }

        if (portalUser.isSuperUser || portalUser.isManager) {
            superHotkeys.push(ButtonModel.CreateRoute("Site Management", "Tenant-Management"));
            if (!portalUser.isManager){
                superHotkeys.push(ButtonModel.CreateRoute("Legacy Site Management", "Tenant-Management-Legacy"));
                //superHotkeys.push(ButtonModel.CreateRoute("Log Viewer", "Log-Viewer"));
                superHotkeys.push(ButtonModel.CreateRoute("Vendor Management", "Vendor-Management"));
                superHotkeys.push(ButtonModel.CreateRoute("Station QR Management", "Station-QR-Management"));
                superHotkeys.push(ButtonModel.CreateRoute("Station Dashboard", "Station-Dashboard"));
                systemTools.push(ButtonModel.CreateRouteWithIcon("Data Exports", "Data-Exports", "/images/icons/database-export.png"));                
            }            
            
        }

        return { showMe, appHotkeys, systemHotkeys, cashierHotkeys, posHotkeys, schedulerHotkeys, superHotkeys, systemTools, _portalUser };
    }
};
</script>

<style scoped>
.section-container {
    display: flex;
    flex-wrap: wrap;    
    margin: 10px;
}

.section-button {
    margin: 10px;
    border: 3px solid gray;
    text-transform: uppercase;
    font-size: 11pt;
}

.section-title {
    padding-left:10px;
    text-transform: uppercase;
    font-size: 10pt;
    /* color: var(--card-body-border); */
}

.section-divider {
    width:99%;
    background-color: var(--card-body-border);
    height: 2px;
}
</style>
