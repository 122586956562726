<template>
    <div class="template-base" style="overflow-y: auto;overflow-x: hidden;">
        <Header Title="Site Management" class="div-header" />
        <div class="menu-bar site-menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="newTenant_show = true" :disabled="fetchingData" id="site_management_add_site" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin" />
            <Button class="x-smaller-button" :buttonModel="btnImport" @on-clicked="migrateTenant_show = true" :disabled="fetchingData" id="site_management_migrate_site" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 10px" v-show="!fetchingData">
                <div>Sites</div>
                <div style="display: flex; flex-direction: row;" class="site-site-selector">
                    <select class="" v-model="currentTenant" @change="tenantSelect" style="width:300px;height:34px" id="site_management_sites">
                        <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantId">{{ tenant.tenantName }}</option>
                    </select>

                    <div>
                        <input ref="refSearchItem" placeholder="search" type="text" class="site-site-search" style="" required list="siteItems" @change="setTenant" />
                    </div>
                    <datalist id="siteItems">
                        <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantName"></option>
                    </datalist>
                </div>
                <div style="padding-top:10px">
                    <button class="button-link" style="width:150px; color:cornflowerblue" @click="downloadPiLoader">
                        Download Pi Loader
                    </button>
                </div>
            </div>
        </div>

        <div class="select-button-container-tenant div-body" v-if="tenantModel != null">
            <div class="setting-item">
                <div class="setting-header">
                    <div>{{ tenantModel.tenantName }}</div>
                    <div class="setting-header-bar"><hr /></div>

                    <div style="display:flex;flex-direction:column;flex-wrap:wrap">
                        <!-- Header -->
                        <div class="button-bar" style="flex-direction:row;justify-content:flex-start;margin-bottom: 20px;">
                            <button class="button-link" style="width:150px" @click="accessSite" id="site_management_access_site">
                                Access Site
                            </button>
                            <button class="button-link" style="width:150px" @click="getVendorInfo">
                                Vendor Integration
                                <span style="margin-top:2px">
                                    <svg height="16" width="16">
                                        <rect width="10" height="10" x="1" y="5" style="fill:transparent;stroke-width:2;stroke:grey" />
                                        <rect width="10" height="10" x="5" y="1" style="fill:white;stroke-width:2;stroke:grey" />
                                    </svg>
                                </span>
                            </button>
                            <div class="tooltip" v-show="showCopyToolTip">
                                <span class="tooltiptext">Copied!</span>
                            </div>
                            <button class="button-link" style="width:150px" @click="tenantReindexMembers" id="site_management_reindex_members" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin">
                                Reindex Members
                            </button>
                            <button class="button-link" style="width:150px;color:green" @click="tenantRecalculateReportData" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin" v-if="false">
                                Recalc Report Data
                            </button>
                            <button class="button-link" style="width:150px;color:green" @click="tenantRecalculateMemberBalances" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin" v-if="false">
                                Recalc Site Balances
                            </button>

                            <button class="button-link" style="width:150px;color:green" @click="siteQuickReport" v-show="portalUser.isSuperAdmin">
                                Site Quick Report
                            </button>
                        </div>

                        <div style="display:flex;flex-direction:row;flex-wrap:wrap;">
                            <!-- Column 1 -->
                            <div style="margin-right:50px">
                                <div style="display:flex;flex-direction:column;">
                                    <div class="setting-input-container site-setting-input-container">
                                        <span class="setting-name">Id</span>
                                        <input class="setting-input-item tenant-input-item" type="text" v-model="tenantModel.tenantId" readonly />
                                    </div>

                                    <div class="site-section">
                                        <div class="site-section-title">Location</div>

                                        <div class="setting-input-container site-setting-input-container">
                                            <span class="setting-name">Address</span>
                                            <textarea class="setting-input-item tenant-input-item" v-model="tenantModel.tenantAddress" rows="3" readonly style="resize:none"></textarea>
                                            <a :href="`https://maps.google.com/?q=${tenantModel.latitude},${tenantModel.longitude}`" target="_blank"><img width="20" src="/images/icons/location-pin.png"/></a>
                                        </div>

                                        <div class="setting-input-container site-setting-input-container">
                                            <span class="setting-name">Timezone</span>
                                            <input class="setting-input-item tenant-input-item" type="text" v-model="tenantModel.timezone" readonly />
                                        </div>

                                        <div class="setting-input-container site-setting-input-container">
                                            <span class="setting-name">Locale</span>
                                            <select class="setting-input-item tenant-input-item tenant-management-dropdown" v-model="tenantModel.locale" @change="onSave" :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin">
                                                <option value="en-us">English (United States)</option>
                                                <option value="en-au">English (Australia)</option>
                                                <option value="en-ca">English (Canada)</option>
                                                <option value="en-gb">English (United Kingdom)</option>
                                                <option value="en-ie">English (Ireland)</option>
                                                <option value="fr-ca">French (Canada)</option>
                                                <option value="es-mx">Spanish (Mexico)</option>
                                                <option value="en-in">English (India)</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <input @change="onSave" type="checkbox" :id="'chkIsEnabled' + tenantModel.tenantId" v-model="tenantModel.isEnabled" :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin" />
                                        <label :for="'chkIsEnabled' + tenantModel.tenantId">Active</label>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <input @change="onSave" type="checkbox" :id="'chkEnablePos' + tenantModel.tenantId" v-model="tenantModel.enablePos" :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin" />
                                        <label :for="'chkEnablePos' + tenantModel.tenantId">Enable POS</label>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container" style="margin-left: 40px;" v-if="tenantModel.enablePos">
                                        <input
                                            @change="onSave"
                                            type="checkbox"
                                            :id="'chkEnableInventoryManagement' + tenantModel.tenantId"
                                            v-model="tenantModel.enableInventoryManagement"
                                            :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                        />
                                        <label :for="'chkEnableInventoryManagement' + tenantModel.tenantId">Enable Inventory Management</label>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <input @change="onSave" type="checkbox" :id="'chkLegacyPinApi' + tenantModel.tenantId" v-model="tenantModel.legacyPinApi" :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin" />
                                        <label :for="'chkLegacyPinApi' + tenantModel.tenantId">Legacy Pin Api</label>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <input @change="onSave" type="checkbox" :id="'chkEnableMemberExport' + tenantModel.tenantId" v-model="tenantModel.enableMemberExport" :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin" />
                                        <label :for="'chkEnableMemberExport' + tenantModel.tenantId">Enable Member Export</label>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <input
                                            @change="onSave"
                                            type="checkbox"
                                            :id="'chkAllowMemberCardsWithAlpha' + tenantModel.tenantId"
                                            v-model="tenantModel.allowMemberCardsWithAlpha"
                                            :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                        />
                                        <label :for="'chkAllowMemberCardsWithAlpha' + tenantModel.tenantId">Allow Alpha Member Card</label>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <input @change="onSave" type="checkbox" :id="'chkVNext' + tenantModel.tenantId" v-model="tenantModel.vNext" :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin" />
                                        <label :for="'chkVNext' + tenantModel.tenantId">vNext</label>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin">
                                        <span class="setting-name">Assigned Manager</span>
                                        <select class="setting-input-item tenant-input-item tenant-management-dropdown" v-model="tenantModel.managerId" @change="onSave">
                                            <option v-for="manager in managers" :key="manager.globalId" :value="manager.username">{{ manager.displayName }}</option>
                                        </select>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <span class="setting-name">Inactivity Timeout (minutes)</span>
                                        <input
                                            @change="onSave"
                                            class="setting-input-item tenant-input-item administrator-input-item-numeric"
                                            type="number"
                                            max="86400"
                                            v-model.number="tenantModel.inactivityTimeout"
                                            :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                        />
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <span class="setting-name" title="20 or under will automatically change to 50">Card reader threshold (milliseconds)</span>
                                        <input
                                            @change="onSave"
                                            class="setting-input-item tenant-input-item administrator-input-item-numeric"
                                            type="number"
                                            max="5000"
                                            v-model.number="tenantModel.cardReaderThreshold"
                                            :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            title="20 or under will automatically change to 50"
                                        />
                                    </div>

                                    <div class="setting-input-container site-setting-input-container">
                                        <span class="setting-name">Cashier button font</span>
                                        <input
                                            @change="onSave"
                                            class="setting-input-item tenant-input-item administrator-input-item-numeric"
                                            type="text"
                                            v-model="tenantModel.cashierOverrideFont"
                                            :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                        />
                                    </div>
                                </div>
                            </div>

                            <!-- Column 2 -->
                            <div style="margin-left: 0px;">
                                <div style="display:flex;flex-direction:column">                                    
                                    <!-- Payment Processor -->
                                    <div class="site-section">
                                        <div class="site-section-title">Payment Procesor</div>
                                        <div class="setting-input-container site-setting-input-container" style="display: flex;justify-content:end ;">
                                            <button class="button-link" style="color:blue" @click="onSave" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin" v-if="paymentDirty">
                                                Save
                                            </button>
                                        </div>
                                        <div class="setting-input-container site-setting-input-container" style="padding-left: 0px;">
                                            <span class="setting-name">Provider</span>
                                            <select
                                                class="setting-input-item tenant-input-item tenant-management-dropdown"
                                                v-model="tenantModel.paymentProcessorType"
                                                @change="paymentDirty = true"
                                                :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            >
                                                <option v-bind:value="PaymentProcessorTypes.None">None</option>
                                                <option v-bind:value="PaymentProcessorTypes.Pineapple">CardPointe</option>
                                                <option v-bind:value="PaymentProcessorTypes.WorldPay">World Pay</option>
                                            </select>
                                        </div>

                                        <!-- Pineapple -->
                                        <div v-if="tenantModel.paymentProcessorType == PaymentProcessorTypes.Pineapple" style="padding-left: 20px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Merchant Id</span>
                                                <input @change="paymentDirty = true" class="setting-input-item tenant-input-item" type="text" v-model="tenantModel.pineappleMerchantId" :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin" />
                                            </div>
                                        </div>

                                        <!-- World Pay -->
                                        <div v-if="tenantModel.paymentProcessorType == PaymentProcessorTypes.WorldPay" style="padding-left: 20px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Acceptor Id</span>
                                                <input
                                                    @change="paymentDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.worldPaySiteSettings.acceptorId"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Account Id</span>
                                                <input
                                                    @change="paymentDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.worldPaySiteSettings.accountId"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Account Token</span>
                                                <input
                                                    @change="paymentDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.worldPaySiteSettings.accountToken"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Terminal Id</span>
                                                <input
                                                    @change="paymentDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.worldPaySiteSettings.terminalId"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>
                                        </div>

                                        <div class="setting-input-container site-setting-input-container" v-if="tenantModel.paymentProcessorType != PaymentProcessorTypes.None">
                                            <input
                                                @change="paymentDirty = true"
                                                type="checkbox"
                                                :id="'chkDisableMobilePay' + tenantModel.tenantId"
                                                v-model="tenantModel.disableMobilePay"
                                                :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            />
                                            <label :for="'chkDisableMobilePay' + tenantModel.tenantId">Disable Mobile/Vendor Pay</label>
                                        </div>
                                    </div>

                                    <!-- Fees -->
                                    <div class="site-section" style="margin-top: 20px;">
                                        <div class="site-section-title">Fees</div>
                                        <div class="setting-input-container site-setting-input-container">
                                            <input @change="onSave" type="checkbox" :id="'chkenableServiceFee' + tenantModel.tenantId" v-model="tenantModel.enableServiceFee" :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin" />
                                            <label :for="'chkenableServiceFee' + tenantModel.tenantId">Enable Select Pi Fees</label>
                                        </div>

                                        <div class="setting-input-container site-setting-input-container">
                                            <span style="margin-left: 15px;" class="setting-name">Credit Card Rate (%)</span>
                                            <input
                                                @change="onSave"
                                                class="setting-input-item tenant-input-item administrator-input-item-numeric"
                                                type="number"
                                                max="200"
                                                v-model.number="tenantModel.serviceFeeCreditCardRate"
                                                :disabled="!tenantModel.enableServiceFee"
                                            />
                                        </div>

                                        <div class="setting-input-container site-setting-input-container">
                                            <span style="margin-left: 15px;" class="setting-name">Credit Card Fixed ({{ Utilities._currencySymbol(tenantModel.locale) }})</span>
                                            <input
                                                @change="onSave"
                                                class="setting-input-item tenant-input-item administrator-input-item-numeric"
                                                type="number"
                                                max="86400"
                                                v-model.number="tenantModel.serviceFeeCreditCardFixed"
                                                :disabled="!tenantModel.enableServiceFee"
                                            />
                                        </div>
                                    </div>

                                    <!-- Third-Party Member Balance -->
                                    <div class="site-section" style="margin-top: 20px;">
                                        <div class="site-section-title">Third-Party Integration</div>
                                        <div class="setting-input-container site-setting-input-container" style="display: flex;justify-content:end ;">
                                            <button class="button-link" style="color:blue" @click="onSave" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin" v-if="thirdPartyDirty">
                                                Save
                                            </button>
                                        </div>

                                        <!-- MiraPay -->
                                        <div class="setting-input-container site-setting-input-container">
                                            <input
                                                @change="disableAllThirdParty('mirapay')"
                                                type="checkbox"
                                                :id="'chkEnableMiraPay' + tenantModel.tenantId"
                                                v-model="tenantModel.enableMiraPay"
                                                :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            />
                                            <label :for="'chkEnableMiraPay' + tenantModel.tenantId">MiraPay Gift Cards</label>
                                        </div>

                                        <!-- Northstar App -->
                                        <div class="setting-input-container site-setting-input-container">
                                            <input
                                                @change="disableAllThirdParty('northstarapp')"
                                                type="checkbox"
                                                :id="'chkEnableNorthStarApp' + tenantModel.tenantId"
                                                v-model="tenantModel.enableNorthstarApp"
                                                :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            />
                                            <label :for="'chkEnableNorthStarApp' + tenantModel.tenantId">Northstar App</label>
                                        </div>

                                        <!-- Northstar POS -->
                                        <div class="setting-input-container site-setting-input-container">
                                            <input
                                                @change="disableAllThirdParty('northstarpos')"
                                                type="checkbox"
                                                :id="'chkEnableNorthStarPos' + tenantModel.tenantId"
                                                v-model="tenantModel.enableNorthstarPos"
                                                :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            />
                                            <label :for="'chkEnableNorthStarPos' + tenantModel.tenantId">Northstar Pos</label>
                                        </div>
                                        <div v-if="tenantModel.enableNorthstarPos" style="padding-left: 20px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Client Endpoint</span>
                                                <input
                                                    @change="thirdPartyDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.northStarSiteSettings.endPoint"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Validation Code</span>
                                                <input
                                                    @change="thirdPartyDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.northStarSiteSettings.validationCode"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Key</span>
                                                <textarea
                                                    rows="3"
                                                    @change="thirdPartyDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.northStarSiteSettings.publicKey"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                ></textarea>
                                            </div>
                                        </div>

                                        <!-- Elite live -->
                                        <div class="setting-input-container site-setting-input-container">
                                            <input
                                                @change="disableAllThirdParty('eliteLive')"
                                                type="checkbox"
                                                :id="'chkEliteLive' + tenantModel.tenantId"
                                                v-model="tenantModel.eliteLiveSettings.isEnabled"
                                                :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            />
                                            <label :for="'chkEliteLive' + tenantModel.tenantId">Elite Live</label>
                                        </div>
                                        <div v-if="tenantModel.eliteLiveSettings.isEnabled" style="padding-left: 20px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Endpoint</span>
                                                <input
                                                    @change="thirdPartyDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.eliteLiveSettings.endpoint"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Username</span>
                                                <input
                                                    @change="thirdPartyDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="text"
                                                    v-model="tenantModel.eliteLiveSettings.username"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Client key</span>
                                                <input
                                                    @change="thirdPartyDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="password"
                                                    v-model="tenantModel.eliteLiveSettings.clientKey"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>
                                        </div>

                                        <!-- Square -->
                                        <div class="setting-input-container site-setting-input-container">
                                            <input
                                                @change="disableAllThirdParty('square')"
                                                type="checkbox"
                                                :id="'chksquare' + tenantModel.tenantId"
                                                v-model="tenantModel.squareSettings.isEnabled"
                                                :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            />
                                            <label :for="'chksquare' + tenantModel.tenantId">Square</label>
                                        </div>
                                        <div v-if="tenantModel.squareSettings.isEnabled" style="padding-left: 20px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Access Token</span>
                                                <input
                                                    @change="thirdPartyDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="password"
                                                    v-model="tenantModel.squareSettings.accessToken"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Signature Key</span>
                                                <input
                                                    @change="thirdPartyDirty = true"
                                                    class="setting-input-item tenant-input-item"
                                                    type="password"
                                                    v-model="tenantModel.squareSettings.signatureKey"
                                                    :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                                />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span class="setting-name">Webhook Url</span>
                                                <input class="setting-input-item tenant-input-item" type="text" v-model="tenantModel.squareSettings.webhookUrl" disabled="true" readonly />
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Payment Services -->
                                    <div class="site-section" style="margin-top: 20px;">
                                        <div class="setting-input-container site-setting-input-container" style="display: flex;justify-content:end ;">
                                            <button class="button-link" style="color:blue" @click="onSave" v-show="portalUser.isSuperUser || portalUser.isSuperAdmin" v-if="paymentServiceDirty">
                                                Save
                                            </button>
                                        </div>
                                        <div class="setting-input-container site-setting-input-container" style="padding-left: 0px;">
                                            <span class="setting-name">Payment Service</span>
                                            <select
                                                class="setting-input-item tenant-input-item tenant-management-dropdown"
                                                v-model="tenantModel.paymentServiceType"
                                                @change="paymentServiceDirty = true"
                                                :disabled="!portalUser.isSuperUser && !portalUser.isSuperAdmin"
                                            >
                                                <option v-bind:value="PaymentServiceTypes.None">None</option>
                                                <option v-bind:value="PaymentServiceTypes.Pineapple">CardPointe</option>
                                                <option v-bind:value="PaymentServiceTypes.Stripe">Stripe</option>
                                                <option v-bind:value="PaymentServiceTypes.WorldPay">World Pay</option>
                                                <option v-bind:value="PaymentServiceTypes.Verifone">Verifone</option>
                                                <option v-bind:value="PaymentServiceTypes.Adyen">Adyen</option>
                                            </select>
                                        </div>

                                        <!-- Pineapple -->
                                        <div v-if="tenantModel.paymentServiceType == PaymentServiceTypes.Pineapple" style="padding-left: 10px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                Set in Payment Process
                                            </div>
                                        </div>

                                        <!-- Stripe -->
                                        <div v-if="tenantModel.paymentServiceType == PaymentServiceTypes.Stripe" style="padding-left: 0px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                <span style="margin-left: 15px;" class="setting-name">Endpoint</span>
                                                <input @change="paymentServiceDirty = true" class="setting-input-item tenant-input-item administrator-input-item" type="text" max="2000" v-model="tenantModel.stripeSettings.endpoint" />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span style="margin-left: 15px;" class="setting-name">Key</span>
                                                <input @change="paymentServiceDirty = true" class="setting-input-item tenant-input-item administrator-input-item" type="password" max="86400" v-model="tenantModel.stripeSettings.secretKey" />
                                            </div>
                                        </div>

                                        <!-- WorldPay -->
                                        <div v-if="tenantModel.paymentServiceType == PaymentServiceTypes.WorldPay" style="padding-left: 10px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                Set in Payment Process
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span style="margin-left: 15px;" class="setting-name">Endpoint</span>
                                                <input @change="paymentServiceDirty = true" class="setting-input-item tenant-input-item administrator-input-item" type="text" max="2000" />
                                            </div>
                                        </div>

                                        <!-- Adyen -->
                                        <div v-if="tenantModel.paymentServiceType == PaymentServiceTypes.Adyen" style="padding-left: 0px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                <span style="margin-left: 15px;" class="setting-name">Api Key</span>
                                                <input @change="paymentServiceDirty = true" class="setting-input-item tenant-input-item administrator-input-item" type="password" max="86400" v-model="tenantModel.adyenSettings.apiKey" />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span style="margin-left: 15px;" class="setting-name">Merchant Account</span>
                                                <input @change="paymentServiceDirty = true" class="setting-input-item tenant-input-item administrator-input-item" type="text" max="86400" v-model="tenantModel.adyenSettings.merchantAccount" />
                                            </div>
                                        </div>

                                        <!-- Verifone -->
                                        <div v-if="tenantModel.paymentServiceType == PaymentServiceTypes.Verifone" style="padding-left: 0px;">
                                            <div class="setting-input-container site-setting-input-container">
                                                <span style="margin-left: 15px;" class="setting-name">Endpoint</span>
                                                <input @change="paymentServiceDirty = true" class="setting-input-item tenant-input-item administrator-input-item" type="text" max="2000" v-model="tenantModel.verifoneSettings.endpoint" />
                                            </div>

                                            <div class="setting-input-container site-setting-input-container">
                                                <span style="margin-left: 15px;" class="setting-name">Key</span>
                                                <input @change="paymentServiceDirty = true" class="setting-input-item tenant-input-item administrator-input-item" type="password" max="86400" v-model="tenantModel.verifoneSettings.entityId" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="setting-input-container site-setting-input-container" v-if="quickReport != null">
                                        <span class="setting-name">First Login</span>
                                        <input class="setting-input-item tenant-input-item" type="text" :value="quickReport.createDateDisplay" readonly />
                                    </div>

                                    <div class="setting-input-container site-setting-input-container" v-if="quickReport != null">
                                        <span class="setting-name">Credit Card Income</span>
                                        <input class="setting-input-item tenant-input-item" type="text" :value="Utilities.toCurrency(quickReport?.creditCardIncome)" readonly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <NewTenant v-if="newTenant_show" @on-cancel="newTenant_show = false" @on-createTenant="createTenant" :tenants="tenants" />
        <MigrateTenant v-if="migrateTenant_show" @on-cancel="migrateTenant_show = false" @on-migrateTenant="migrateTenant" :tenants="tenants" />
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted, watch } from "@vue/runtime-core";
import apiTenant from "@/api/apiTenant";
import NewTenant from "@/views/components/Shared/NewTenant";
import MigrateTenant from "@/views/components/Shared/MigrateTenant";
import apiToken from "@/api/apiToken";
import apiStation from "@/api/apiStation";
import router from "@/router";
import apiUser from "@/api/apiUser";
import PortalUserModel from "@/common/models/PortalUserModel";
import apiLogging from "@/api/apiLogging";
import Utilities from "@/common/utilities";
import { PaymentProcessorTypes, PaymentServiceTypes } from "@/common/enums";
import WorldPaySiteSettingsModel from "@/common/models/WorldPaySiteSettingsModel";

export default {
    components: { Header, Button, NewTenant, MigrateTenant },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const fetchingData = ref(true);

        const tenants = ref([]);
        const managers = ref([]);
        const searchNameText = ref("");
        const newTenant_show = ref(false);
        const migrateTenant_show = ref(false);
        const tenantModel = ref();
        const inputSearch = ref(null);
        const currentTenant = ref("");
        const showCopyToolTip = ref(false);
        const quickReport = ref(null);
        const paymentDirty = ref(false);
        const paymentServiceDirty = ref(false);
        const thirdPartyDirty = ref(false);
        const refSearchItem = ref(null);

        const btnExit = ButtonModel.CreateRoute("", "Home");
        const btnAdd = ButtonModel.CreateClick("");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";
        btnAdd.iconPath = "/images/icons/add.png";
        btnAdd.title = "Add Site...";

        const btnImport = ButtonModel.CreateClick("");
        btnImport.iconPath = "/images/icons/import.png";
        btnImport.title = "Migrate Site...";

        //const portalUser = ref()
        var portalUser = PortalUserModel.fromToken();

        onMounted(() => {
            getData();
        });

        const onSave = async () => {
            if (!tenantModel.value.enablePos) tenantModel.value.enableInventoryManagement = false;

            // For backward compatibility
            if (tenantModel.value.paymentProcessorType == PaymentProcessorTypes.None) {
                tenantModel.value.pineappleMerchantId = "";
                tenantModel.value.worldPaySiteSettings = new WorldPaySiteSettingsModel();
            } else if (tenantModel.value.paymentProcessorType == PaymentProcessorTypes.Pineapple) {
                tenantModel.value.worldPaySiteSettings = new WorldPaySiteSettingsModel();
            } else if (tenantModel.value.paymentProcessorType == PaymentProcessorTypes.WorldPay) {
                tenantModel.value.pineappleMerchantId = "";
            }

            // Clear payment services
            if (tenantModel.value.paymentServiceType !== PaymentServiceTypes.Stripe) {
                tenantModel.value.stripeSettings.secretKey = "";
                tenantModel.value.stripeSettings.endpoint = "";
            }
            if (tenantModel.value.paymentServiceType !== PaymentServiceTypes.Verifone) {
                tenantModel.value.verifoneSettings.entityId = "";
                tenantModel.value.verifoneSettings.endpoint = "";
            }
            if (tenantModel.value.paymentServiceType !== PaymentServiceTypes.Adyen) {
                tenantModel.value.adyenSettings.apiKey = "";
                tenantModel.value.adyenSettings.merchantAccount = "";
            }

            try {
                emitter.emit("server-call-start", "Saving...");
                fetchingData.value = true;
                await apiTenant.save(tenantModel.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving site", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
            paymentDirty.value = false;
            paymentServiceDirty.value = false;
            thirdPartyDirty.value = false;
        };

        const createTenant = async (tenantName, tenantAddress) => {
            try {
                emitter.emit("server-call-start", "Creating site...");
                fetchingData.value = true;
                var newTenant = await apiTenant.create(tenantName, tenantAddress);
                tenants.value.push(newTenant);
                newTenant_show.value = false;

                currentTenant.value = newTenant.tenantId;
                await tenantSelect();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error creating site", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const migrateTenant = async (tenantId) => {
            try {
                emitter.emit("server-call-start", "Migrating site...");
                fetchingData.value = true;
                var newTenant = await apiTenant.migrate(tenantId);
                tenants.value.push(newTenant);
                migrateTenant_show.value = false;

                currentTenant.value = newTenant.tenantId;
                refSearchItem.value.value = "";
                await tenantSelect();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error migrating site", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                var items = await apiTenant.getTenantList();
                tenants.value = items;

                if (portalUser.isSuperUser || portalUser.isSuperAdmin) managers.value = await apiUser.getAllManagers();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const setTenant = async () => {
            currentTenant.value = tenants.value.filter((x) => x.tenantName == refSearchItem.value.value)[0].tenantId;
            await tenantSelect();
        };

        const tenantSelect = async () => {
            try {
                emitter.emit("server-call-start", "Loading site information...");
                fetchingData.value = true;
                quickReport.value = null;
                tenantModel.value = await apiTenant.get(currentTenant.value);
                refSearchItem.value.value = "";
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading site information", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const accessSite = async () => {
            try {
                emitter.emit("server-call-start", "Logging into site...");
                fetchingData.value = true;
                var token = await apiToken.assignTenant(tenantModel.value.tenantId);
                if (token !== null) {
                    localStorage.setItem("token", token);
                    router.replace("/Home");
                }
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error logging into site", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const getVendorInfo = () => {
            var data = `Site Name: ${tenantModel.value.tenantName}\nSite Id: ${tenantModel.value.tenantId}\nUsername: ${tenantModel.value.username}\nPassword: ${tenantModel.value.passkey}\nVendor Role: ${tenantModel.value.vendorRole}`;

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = data;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            showCopyToolTip.value = true;
            setTimeout(() => {
                showCopyToolTip.value = false;
            }, 1000);
        };

        const downloadPiLoader = async () => {
            try {
                emitter.emit("server-call-start", "Downloading..");
                fetchingData.value = true;
                var piLoaderCode = await apiStation.piLoader();
                var link = document.createElement("a");
                link.setAttribute("download", "dispense.py");
                link.href = makeTextFile(piLoaderCode);
                document.body.appendChild(link);
                var event = new MouseEvent("click");
                link.dispatchEvent(event);
                document.body.removeChild(link);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error downloading", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const makeTextFile = (text) => {
            var data = new Blob([text], { type: "text/plain" });

            // If we are replacing a previously generated file we need to
            // manually revoke the object URL to avoid memory leaks.
            if (textFile !== null) {
                window.URL.revokeObjectURL(textFile);
            }

            var textFile = window.URL.createObjectURL(data);

            return textFile;
        };

        const tenantReindexMembers = async () => {
            try {
                emitter.emit("server-call-start", "Reindexing site. Please wait...");
                fetchingData.value = true;
                await apiTenant.reindexMembers(currentTenant.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error reindexing site", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const tenantRecalculateReportData = async () => {
            var initDate = new Date().yyyy_mm_dd();
            initDate = "2022-07-18";
            var date = prompt("Enter date (yyyy-mm-dd)", initDate);
            if (date !== null && date.trim().length > 0) {
                try {
                    emitter.emit("server-call-start", "Recalculating report data...");
                    var dataValues = await apiLogging.reportFix(currentTenant.value, date);

                    var element = document.createElement("a");
                    element.setAttribute("href", "data:tsv/plain;charset=utf-8," + encodeURIComponent(dataValues));
                    element.setAttribute("download", "ReportData.tsv");

                    element.style.display = "none";
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error recalculating report data", err]);
                }
                emitter.emit("server-call-stop");
            }
        };

        const tenantRecalculateMemberBalances = async () => {
            try {
                emitter.emit("server-call-start", "Fixing report...");
                var totals = await apiLogging.reportFixMemberBalance(currentTenant.value);
                alert(JSON.stringify(totals));
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error fixing reports", err]);
            }
            emitter.emit("server-call-stop");
        };

        const siteQuickReport = async () => {
            try {
                emitter.emit("server-call-start", "Loading report...");
                quickReport.value = await apiTenant.getQuickReport(currentTenant.value);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading reports", err]);
            }
            emitter.emit("server-call-stop");
        };

        const disableAllThirdParty = (except) => {
            thirdPartyDirty.value = true;

            if (except != "mirapay") tenantModel.value.enableMiraPay = false;

            if (except != "northstarpos") tenantModel.value.enableNorthstarPos = false;

            if (except != "northstarapp") tenantModel.value.enableNorthstarApp = false;

            if (except != "eliteLive") tenantModel.value.eliteLiveSettings.isEnabled = false;

            if (except != "square") tenantModel.value.squareSettings.isEnabled = false;
        };

        watch(tenantModel.value?.enablePOS, () => {});

        return {
            btnExit,
            btnAdd,
            tenants,
            searchNameText,
            newTenant_show,
            tenantModel,
            createTenant,
            tenantSelect,
            inputSearch,
            fetchingData,
            currentTenant,
            onSave,
            accessSite,
            getVendorInfo,
            showCopyToolTip,
            downloadPiLoader,
            btnImport,
            migrateTenant_show,
            migrateTenant,
            tenantReindexMembers,
            managers,
            portalUser,
            tenantRecalculateReportData,
            tenantRecalculateMemberBalances,
            siteQuickReport,
            quickReport,
            Utilities,
            PaymentProcessorTypes,
            PaymentServiceTypes,
            paymentDirty,
            paymentServiceDirty,
            thirdPartyDirty,
            setTenant,
            refSearchItem,
            disableAllThirdParty
        };
    }
};
</script>

<style scoped>
.tenant-management-dropdown {
    width: 220px;
}

@media (orientation: landscape) {
    .site-site-search {
        margin-left: 10px;
        width: 300px;
        height: 22px;
    }

    .site-section {
        border: 1px black solid;
        border-radius: 5px;
        background-color: #efefef;
    }

    .site-section-title {
        margin: 10px;
    }
}

@media (orientation: portrait) {
    .site-menu-bar {
        flex-wrap: wrap;
    }

    .site-site-selector {
        flex-wrap: wrap;
        padding: 10px;
    }

    .site-site-search {
        margin-top: 10px;
        width: 300px;
        height: 22px;
    }

    .site-setting-input-container {
        flex-wrap: wrap;
        padding-left: 0px;
        margin-left: 0px;
        font-size: 10pt;
    }

    .site-section {
        border: 1px black solid;
        border-radius: 5px;
        background-color: #efefef;
        padding-left: 10px;
        width: 90vw;
    }

    .site-section-title {
        margin-top: 10px;
    }
}
</style>
