<template>
    <div class="backdrop" style="z-index:999999" @click.self="onOk">
        <div class="alert-box">
            <div style="display:flex;justify-content:center">{{ caption }}</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div style="display:flex;justify-content:flex-start; flex-direction: row;height:100%; margin-left:10px">
                    <div style="display:flex;justify-content:center; flex-direction: column">
                        <img src="../../../assets/alert.png" style="width:50px;margin:3px;" />
                    </div>
                    <div style="display:flex;justify-content:center; flex-direction: column;margin-left:30px;flex-wrap:wrap">
                        <pre v-show="/\r|\n/.exec(message.toString().trim()) != null">{{ message }}</pre>
                        <span v-show="/\r|\n/.exec(message.toString().trim()) == null">{{ message }}</span>
                    </div>
                </div>
                <div class="alert-box-button-bar">
                    <button class="alert-box-button" @click="onOk" ref="btnOk">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
    props: ["caption", "message"],
    setup(props, { emit }) {
        const btnOk = ref(null);

        const onOk = () => {
            emit("on-ok");
        };

        onMounted(() => {
            btnOk.value.focus();
        });

        //var match = /\r|\n/.exec(props.message.toString().trim());
        //console.log(props.message,match)

        return { onOk, btnOk };
    }
};
</script>

<style>
@media (orientation: landscape) {
    .alert-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 35%;
        left: 50%;
        margin-top: -105px;
        margin-left: -255px;
        width: 600px;
        height: 200px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 10px;
        border: 1px solid var(--card-body-border);
    }
}

@media (orientation: portrait) {
    .alert-box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 35%;
        left: 50%;
        margin-top: -105px;
        margin-left: -255px;
        width: 600px;
        height: 200px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 10px;
        border: 1px solid var(--card-body-border);
    }
}

.alert-box-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.alert-box-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 75px;
    cursor: pointer;
}
</style>
