<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-item">
            <div style="display:flex;justify-content:center">{{ title }}</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;flex-wrap: wrap;height:100%">
                <div class="tenant-input-container">
                    <span>Name</span>
                    <input @focus="clearError" ref="refInput" class="item-input" type="text" v-model="itemName" :maxlength="maxLength" @keyup="processKeyPress" />
                </div>
                <div style="color:red;font-size:10pt;text-align:center">
                    {{ errorMessage }}
                </div>
                <div class="new-item-button-bar">
                    <button class="new-item-button" @click="onOk">
                        {{ okText }}
                    </button>
                    <button class="new-item-button" @click="onCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
    props: ["title", "okText", "minLength", "maxLength"],
    setup(props, { emit }) {
        const itemName = ref("");
        const refInput = ref(null);
        const errorMessage = ref("");

        const onCancel = () => {
            emit("on-cancel");
        };

        const onOk = () => {
            errorMessage.value = "";

            // Validate Tenant name
            if (itemName.value.length < props.minLength || itemName.value.length > props.maxLength) {
                errorMessage.value = `Name invalid. Must be between ${props.minLength} and ${props.maxLength} characters.`;
                return;
            }

            emit("on-ok", itemName.value);
            emit("on-cancel");
        };

        const clearError = () => {
            errorMessage.value = "";
        };

        onMounted(() => {
            refInput.value.focus();
        });

        const processKeyPress = (event) => {
            if (event.keyCode === 13) onOk();
            if (event.keyCode === 27) onCancel();
        };

        return { onCancel, refInput, onOk, itemName, errorMessage, clearError, processKeyPress };
    }
};
</script>

<style scoped>
@media (orientation: landscape) {
    .new-item {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -205px;
        margin-left: -255px;
        width: 500px;
        height: 170px;
        background: var(--main-background);
        border-radius: 10px;
        padding: 30px;
        border: 1px solid var(--card-body-border);
    }

    .item-input {
        width: 300px;
    }

    .tenant-input-container {
        display: flex;
        justify-content: space-between;
        margin: 10px;
    }
}

@media (orientation: portrait) {
    .new-item {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 20%;
        left: 5%;
        /* margin-top: -75px; */
        /* margin-left: -210px; */
        width: 80vw;
        height: 170px;
        background: var(--main-background);
        border-radius: 10px;
        /* padding: 30px; */
        border: 1px solid var(--card-body-border);
    }

    .item-input {
        width: 70vw;
    }

    .tenant-input-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin: 10px;
        flex-wrap: wrap;
    }
}

.new-item-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-item-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
    cursor: pointer;
}

.tenant-item {
    width: 500px;
    min-width: 100px;
}
</style>
